import React, {useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import FlashMessage from '../components/FlashMessage';
import { useAuth } from '../components/Auth';
import { Spinner } from 'react-bootstrap'

export function GroupCreate() {
    const duration = 10000;
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [saved, setSaved] = useState(null);
    const [submitting, setSubmitting] = useState(null);
    let auth = useAuth();
    const title = "Create new group";
    document.title = title;

    const onSubmit = data => {
        setSaved(false);
        setSubmitting(true);
        fetch(process.env.REACT_APP_API+'/groups/', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json()
        }).then(data => {
            setSaved(true);
            setSubmitting(false);
            if(data.id > 0){
                setTimeout(function(){
                    navigate({pathname: "/groups/"+data.id});
                },1000);
            }
        }).catch((e) => {
            setError(e.name + ': ' + e.message);
            setSubmitting(false);
        });
    }

    return (
        <div>
            <h2>{title}</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input ref={register({ required: true })} required="required" defaultValue="" name="name" type="text" className="form-control" placeholder="Name" autoFocus />
                </div>
                {error && (
                    <FlashMessage duration={duration} persistOnHover={true}>
                        <div className="alert alert-danger" role="alert">{error}</div>
                    </FlashMessage>
                )}
                {saved && (
                    <FlashMessage duration="2000">
                        <div className="alert alert-success my-2 text-center" role="alert">
                            <span>Group successfully created</span>
                        </div>
                    </FlashMessage>
                )}
                <button className="btn btn-lg btn-primary btn-block mt-4" type="submit">
                    {submitting ? (
                        <Spinner animation="border" variant="light" size="sm"></Spinner>
                    ) : "Save"}
                </button>
            </form>
        </div>
    );
}
