import React, { useState, useEffect } from "react"
import { useAuth } from '../components/Auth'
import { Link } from "react-router-dom"

import { Row, Col } from 'react-bootstrap'
import { MinutesPerDay } from "../components/charts/MinutesPerDay"
import { LoginsPerDay } from "../components/charts/LoginsPerDay"
import { WithLoading } from "../components/WithLoading"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faPen } from '@fortawesome/free-solid-svg-icons'

export function Dashboard() {
    let auth = useAuth()
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)
    const [stats, setStats] = useState({})
    const [refresh, setRefresh] = useState(0)

    const format = (u) => {
        if (u == null) {
            return 'nobody'
        }
        if (u.firstname.length > 0 || u.lastname.length > 0) {
            return u.firstname + ' ' + u.lastname
        }
        return u.alias
    }

    const today = new Date()
    document.title = "Dashboard " + today.toLocaleDateString("en-UK", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })

    useEffect(() => {
        fetch(process.env.REACT_APP_API + '/statistics/dashboard', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        }).then(res => {
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            return res.json()
        }).then(
            (result) => {
                setStats(result)
                setLoading(false)
            },
            (error) => {
                setError(error)
            }
        )
        const interval = setInterval(() => {
            setRefresh(refresh + 1)
        }, 30000)
        return () => clearInterval(interval)
    }, [auth, refresh])

    if (error) {
        return <div className="alert alert-danger" role="alert">Error: {error.message}</div>;
    } else {
        return (
            <Row>
                <Col sm="6">
                    <h3 className="text-center">Minutes per day</h3>
                    <WithLoading isLoading={loading}>
                        <MinutesPerDay stats={stats.mpd} />
                    </WithLoading>
                </Col>
                <Col sm="6">
                    <h3 className="text-center">Logins per day</h3>
                    <WithLoading isLoading={loading}>
                        <LoginsPerDay stats={stats.logins} />
                    </WithLoading>
                </Col>
                <Col sm="12">
                    <h3 className="text-center">Active users</h3>
                    <WithLoading isLoading={loading}>
                        <div className="table-responsive users">
                            <table className="table table-striped table-hover table-sm">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>#</th>
                                        <th>Alias</th>
                                        <th>Firstname</th>
                                        <th>Lastname</th>
                                        <th>Email</th>
                                        {auth.scopes.includes("admin") && (
                                            <th>Mandant</th>
                                        )}
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(stats.activeUsers) && stats.activeUsers.map(aU => {
                                        const d = new Date(aU.activity)
                                        return (
                                            <tr key={"activeUser" + aU.id} className="online">
                                                <td><FontAwesomeIcon icon={faCircle} title={"Last contact: " + d.toLocaleTimeString()} /></td>
                                                <td>{aU.id}</td>
                                                <td>{aU.alias}</td>
                                                <td>{aU.firstname}</td>
                                                <td>{aU.lastname}</td>
                                                <td>{aU.email}</td>
                                                {auth.scopes.includes("admin") && (
                                                    <td>
                                                        {format(aU.owner)}
                                                    </td>
                                                )}
                                                <td>
                                                    <Link to={"/users/" + aU.id} title={"Edit #" + aU.id}>
                                                        <button type="button" className="btn btn-primary btn-sm mr-2">
                                                            <FontAwesomeIcon icon={faPen} />
                                                        </button>
                                                    </Link>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </WithLoading>
                </Col>
            </Row>
        )
    }
}