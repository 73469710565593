import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import FlashMessage from '../components/FlashMessage';
import { useNavigate } from "react-router-dom"
import { useAuth } from '../components/Auth'
import { AxoneSpinner } from '../components/AxoneSpinner'
import { Spinner } from 'react-bootstrap'

export function ReceiverCreate() {
    const duration = 10000;
    const { register, handleSubmit, reset } = useForm();
    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [saved, setSaved] = useState(null);
    const [submitting, setSubmitting] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [users, setUsers] = useState([]);
    let auth = useAuth();
    const title = "Create new receiver";

    const onSubmit = data => {
        setSaved(false);
        setSubmitting(true);
        fetch(process.env.REACT_APP_API + '/receivers/', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json()
        }).then(data => {
            setSaved(true);
            setSubmitting(false);
            if (data.id > 0) {
                setTimeout(function () {
                    navigate({ pathname: "/receivers/" + data.id });
                }, 1000);
            }
        }).catch((error) => {
            setError(error);
            setSubmitting(false);
        });
    }
    const fetchUsers = function (auth, reset) {
        fetch(process.env.REACT_APP_API + '/users/?order_by=lastname&limit=100&skip=0&asc=1', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setUsers(result);
                    setIsLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                    setIsLoaded(true);
                }
            )
    }
    useEffect(() => {
        document.title = title;
        fetchUsers(auth, reset);
    }, [auth, title, reset])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <AxoneSpinner />;
    } else {
        return (
            <div>
                <h2>{title}</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input ref={register({ required: true })} required="required" defaultValue="" name="name" type="text" className="form-control" placeholder="Name" autoFocus />
                    </div>
                    <div className="form-group">
                        <label htmlFor="type">Type</label>
                        <input ref={register({ required: true })} required="required" defaultValue="" name="type" type="text" className="form-control" placeholder="Type" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="imei">IMEI</label>
                        <input ref={register({ required: true })} required="required" defaultValue="" name="imei" type="text" className="form-control" placeholder="Imei" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="serialnumber">Serialnumber</label>
                        <input ref={register({ required: true })} required="required" defaultValue="" name="serialnumber" type="text" className="form-control" placeholder="Serialnumber" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="tel">Telephone</label>
                        <input ref={register} defaultValue="" name="tel" type="text" className="form-control" placeholder="Telephone" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="apple_id">Apple ID</label>
                        <input ref={register} defaultValue="" name="apple_id" type="email" className="form-control" placeholder="Apple ID" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="customer">Client</label>
                        <input ref={register} defaultValue="" name="customer" type="text" className="form-control" placeholder="Client" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="user_id">User</label>
                        <select ref={register({ validate: value => parseInt(value) >= 0 })} defaultValue="0" name="user_id" className="form-control">
                            <option value="0">nobody</option>
                            {users.map((user) => (
                                <option key={user.id} value={user.id}>{user.firstname.length > 0 || user.lastname.length > 0 ? user.firstname + ' ' + user.lastname : user.alias} {user.email.length > 0 ? '(' + user.email + ')' : ''}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="comment">Comment</label>
                        <textarea ref={register} defaultValue="" name="comment" className="form-control" placeholder="Comment" />
                    </div>
                    {error && (
                        <FlashMessage duration={duration} persistOnHover={true}>
                            <div className="alert alert-danger" role="alert">{error}</div>
                        </FlashMessage>
                    )}
                    {saved && (
                        <FlashMessage duration={2000}>
                            <div className="alert alert-success my-2 text-center" role="alert">
                                <span>Receiver successfully created</span>
                            </div>
                        </FlashMessage>
                    )}
                    <button disabled={submitting} className="btn btn-lg btn-primary btn-block mt-4" type="submit">
                        {submitting ? (
                            <Spinner animation="border" variant="light" size="sm"></Spinner>
                        ) : "Save"}
                    </button>
                </form>
            </div>
        );
    }
}
