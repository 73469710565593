import { React, useEffect, useState } from "react";
import FlashMessage from './FlashMessage';
import ReactPaginate from 'react-paginate';
import { Link } from "react-router-dom"
import { useAuth } from '../components/Auth';
import { AxoneSpinner } from './AxoneSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

export function ObjectListing(props) {
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');
    const [activefilters, setActivefilters] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [deleted, setDeleted] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [skip, setSkip] = useState(0);
    const [lastTotal, setLastTotal] = useState(0);
    const [orderBy, setOrderBy] = useState(props.defaultSort);
    const [asc, setAsc] = useState(props.defaultAsc !== 'false');
    const itemsPerPage = 20;
    const objectname = props.objectname;
    let auth = useAuth();

    const hasActions = props.add || props.edit || props.delete || props.actions?.length > 0;

    const useuuid = props.useuuid ? props.useuuid : false;
    var itemTitle  = useuuid ? 'uuid' : 'id'
    props.attributes.every((v) => {
        if (v.key === 'name' || v.key === 'title'){
            itemTitle = v.key
            return false
        }
        return true
    })


    const onPageChange = (data) => {
        setSkip(data.selected * itemsPerPage);
    }
    const toggleOrder = (key, parent) => {
        if (parent !== "undefined" && typeof parent !== "undefined" && parent !== null) {
            key = parent + '.' + key
        }
        setOrderBy(key);
        setAsc(!asc);
    }
    const getToggleClasss = key => {
        var ret = 'btn-order-toggle'
        if (key === orderBy) {
            if (asc) {
                ret += ' asc';
            } else {
                ret += ' desc';
            }
        }
        return ret;
    }
    const getVal = (item, attribute) => {
        if (Array.isArray(attribute.key)) {
            var val = [];
            attribute.key.forEach(key => {
                if (typeof attribute.parent !== "undefined" && typeof item[attribute.parent] !== "undefined" && item[attribute.parent] !== null) {
                    val.push(item[attribute.parent][key]);
                } else {
                    val.push(item[key]);
                }
            });
            return val.join(' ');
        }
        if (typeof attribute.parent !== "undefined" && typeof item[attribute.parent] !== "undefined" && item[attribute.parent] !== null) {
            return item[attribute.parent][attribute.key];
        } else {
            return item[attribute.key];
        }
    }

    const getFormattedVal = (item, attribute, parent_row = null) => {
        if (typeof attribute.format !== "undefined") {
            return attribute.format(getVal(item, attribute), item, parent_row);
        }
        return getVal(item, attribute);
    }
    const deleteItem = function (item) {
        if (window.confirm('Are you sure you wish to delete #' + item + '?')) {
            fetch(process.env.REACT_APP_API + '/' + objectname + '/' + item, {
                method: 'DELETE',
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + auth.token
                },
            })
                .then(
                    (response) => {
                        if (!response.ok) {
                            if (response.status === 409) {
                                setError({ message: 'Item is required for related data and cannot be deleted at this moment.' });
                                setTimeout(() => {
                                    setError(null)
                                }, 5000)
                            }
                            setDeleted(false);
                        } else {
                            setDeleted(true);
                            setRefreshKey(oldKey => oldKey + 1)
                        }
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        setError(error);
                    }
                )
        }
    }
    useEffect(() => {
        document.title = props.title
        var uri = '';
        if (lastTotal > Date.now() - 60000) {
            setIsLoaded(false)
            var action = objectname
            if (props.action?.length > 0) {
                action += '/' + props.action
            }
            uri = process.env.REACT_APP_API + '/' + action + '/?order_by=' + orderBy + '&asc=' + asc + '&skip=' + skip + '&limit=' + itemsPerPage;
            if (props.search && search.length > 0) {
                uri += '&filter=' + encodeURIComponent(search)
            }
            if (activefilters.length > 0) {
                activefilters.forEach((af) => {
                    uri += '&' + encodeURIComponent(af.key) + '=' + encodeURIComponent(af.value)
                })
            }
            fetch(uri, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + auth.token
                },
            }).then(res => res.json())
                .then(
                    (result) => {
                        setIsLoaded(true);
                        setItems(result);
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    }
                )
        } else {
            uri = process.env.REACT_APP_API + '/' + objectname + '/total';
            if (props.search && search.length > 0) {
                uri += '?filter=' + encodeURIComponent(search);
            }
            fetch(uri, {
                method: 'GET',
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + auth.token
                },
            }).then(res => res.json())
                .then(
                    (result) => {
                        setPageCount(Math.ceil(result / itemsPerPage));
                        setLastTotal(Date.now());
                    },
                    (error) => {
                        setError(error);
                    }
                )
        }
        const interval = setInterval(() => {
            setLastTotal(0);
        }, 60000)

        return () => clearInterval(interval)

    }, [auth, skip, lastTotal, objectname, refreshKey, asc, orderBy, props.refresh, props.title, props.search, props.action, search, activefilters])


    return (
        <div className={props.objectname}>
            {error && (
                <div className="alert alert-danger" role="alert">Error: {error.message}</div>
            )}
            <div className="row">
                <div className="col-3">
                    <h2><FontAwesomeIcon icon={props.icon} /> {props.title}</h2>
                </div>
                <div className="col-9 inlines text-right">
                    {props.search && Array.isArray(props.filters) && props.filters.map((filter, i) => (
                        <div className="d-inline mr-2" key={"filter" + i}>
                            <label className="mr-2">
                                {filter.label}
                            </label>
                            <select className="form-control" onChange={(e) => {
                                setLastTotal(0);
                                setActivefilters([
                                    {
                                        key: filter.requestAttribute,
                                        value: e.target.value
                                    }
                                ])
                            }}>
                                <option value="0">all</option>
                                {filter.options.map((option, j) => (
                                    <option key={"filter" + i + 'option' + j} value={option.id}>
                                        {filter.key ? (
                                            filter.toOption(option[filter.key])
                                        ) : filter.toOption(option)}

                                    </option>
                                ))}
                            </select>
                        </div>
                    )
                    )}
                    {props.search && (
                        <input className="form-control" type="text" name="search" placeholder="Search" onChange={(e) => { setLastTotal(0); setSearch(e.target.value); }} />
                    )}
                </div>
            </div>
            {deleted && (
                <FlashMessage duration={2000}>
                    <div className="alert alert-success my-2" role="alert">
                        <span>{props.title} successfully deleted</span>
                    </div>
                </FlashMessage>
            )}
            <div className="table-responsive refreshing-table">
                <table className="table table-striped table-hover table-sm">
                    <thead>
                        <tr>
                            {Array.isArray(props.attributes) && props.attributes.map(attribute => (
                                <th key={attribute.label}>
                                    {attribute.order ? (
                                        <button className={getToggleClasss(attribute.key)} onClick={() => { toggleOrder(attribute.key, attribute.parent) }}>
                                            {attribute.label}
                                        </button>
                                    ) : attribute.label}
                                </th>
                            ))}
                            {hasActions &&
                                <th>
                                    {props.add &&
                                        <Link to={"/" + objectname + "/create"} title={"Create new " + props.title}>
                                            <button type="button" className="btn btn-primary btn-sm">
                                                <FontAwesomeIcon icon={faPlus} />
                                            </button>
                                        </Link>
                                    }
                                </th>
                            }
                        </tr>
                    </thead>
                    {!isLoaded && (
                        <tbody>
                            <AxoneSpinner table={props.attributes.length + 1} />
                        </tbody>
                    )}
                    {Array.isArray(items) && items.map(item => (
                        <tbody key={item.id}>
                            <tr className={props.rowClass ? props.rowClass(item) : ''}>
                                {Array.isArray(props.attributes) && props.attributes.map(attribute => (
                                    <td className={typeof attribute.click === 'function' ? 'pointer' : ''} key={item.id + '_' + attribute.label} onClick={() => {
                                        if (typeof attribute.click === 'function') {
                                            attribute.click(item)
                                        }
                                    }}>
                                        {getFormattedVal(item, attribute)}
                                    </td>
                                ))}
                                {hasActions &&
                                    <td>
                                        {Array.isArray(props.actions) && props.actions.map((action, i) => (
                                            ((typeof action.condition !== 'function' || action.condition(item))) && (
                                                <button onClick={() => { action.click(useuuid ? item.uuid : item.id, item) }} type="button" title={action.title} className="btn btn-primary btn-sm mr-2" key={item.id + '_action_' + i}>
                                                    <FontAwesomeIcon icon={action.icon} />
                                                </button>
                                            )
                                        ))}
                                        {props.edit &&
                                            <Link to={"/" + objectname + "/" + (useuuid ? item.uuid : item.id)} title={"Edit '" + item[itemTitle]+"'"}>
                                                <button type="button" className="btn btn-primary btn-sm mr-2">
                                                    <FontAwesomeIcon icon={faPen} />
                                                </button>
                                            </Link>
                                        }
                                        {props.delete &&
                                            <button type="button" className="btn btn-primary btn-sm mr-2" onClick={() => { deleteItem(useuuid ? item.uuid : item.id, item[itemTitle]) }}>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        }
                                    </td>
                                }
                            </tr>
                            {props.subrows?.length > 0 && props.subrows[0].hidden(item.id) !== true && Array.isArray(item[props.subrows[0].model]) && item[props.subrows[0].model].map(subrow => (
                                <tr className={props.subrows[0].rowClass ? props.subrows[0].rowClass(subrow) : ''} key={item.id + '_subrow_' + subrow.id}>
                                    {Array.isArray(props.subrows) && props.subrows.map(subcol => (
                                        <td className={subcol.class} colSpan={subcol.colspan > 1 ? subcol.colspan : 1} key={item.id + '_subrow_' + subrow.id + '_' + subcol.label}>
                                            {getFormattedVal(subrow, subcol, item)}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    ))}
                </table>
            </div>
            {pageCount > 1 && (
                <nav>
                    <ReactPaginate
                        previousLabel={'previous'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={skip / itemsPerPage}
                        onPageChange={onPageChange}
                        containerClassName={'pagination'}
                        pageClassName={'page-item'}
                        pageLinkClassName={'page-link'}
                        subContainerClassName={'pages pagination'}
                        previousClassName={'page-item'}
                        previousLinkClassName={'page-link'}
                        nextClassName={'page-item'}
                        nextLinkClassName={'page-link'}
                        activeClassName={'active'}
                    />
                </nav>
            )}
        </div>
    );
}

