import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';

export function MinutesPerDay(props) {
  const opacity = 1;
  const renderSpecialDot = (props) => {
    const { cx, cy, stroke, key } = props;

    if (cx === +cx && cy === +cy) {
      return <path d={`M${cx - 2},${cy - 2}h4v4h-4Z`} fill={stroke} key={key} />;
    }

    return null;
  };
  return (
    <LineChart width={600} height={338} data={props.stats} syncId="MinutesPerDay">
      <XAxis type="category" dataKey="day" height={40}>
        {/*<Label value="Date" position="insideBottom" stroke="#777"/>*/}
      </XAxis>
      <YAxis type="number" unit="" width={80}>
        {/*<Label value="Minutes" position="insideLeft" angle={90} stroke="#777"/>*/}
      </YAxis>
      <Tooltip trigger="click" />
      <Line
        key="duration"
        type="monotone"
        dataKey="duration"
        stroke="#f50000"
        dot={renderSpecialDot}
        strokeOpacity={opacity}
      >
      </Line>
    </LineChart>
  )
}