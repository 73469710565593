import React, { useEffect, useState } from "react"
import { useAuth } from '../components/Auth'
import { AxoneSpinner } from '../components/AxoneSpinner'
import FlashMessage from '../components/FlashMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlus, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom"

export function Groups() {
    const [error, setError] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [deleted, setDeleted] = useState(null);
    const [refreshKey, setRefreshKey] = useState(0);
    let auth = useAuth();
    const deleteItem = function(item){
        if (window.confirm('Are you sure you wish to delete #'+item+'?')) {
            setError('');
            fetch(process.env.REACT_APP_API + '/groups/'+item, {
                method: 'DELETE',
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + auth.token
                },
            }).then(response => {
                return response.text().then(data => ({ok: response.ok, status: response.status, body: data}))
            }).then(data => {
                if([409].includes(data.status)){
                    throw new Error(data.body);
                }
                if(!data.ok){
                    throw new Error('Network response was not ok');
                }
                setDeleted(true);
                setRefreshKey(oldKey => oldKey +1)
            }).catch((e) => {
                setError(e.name + ': ' + e.message);
                setDeleted(false);
            });
        }
    }
    useEffect(() => {
        fetch(process.env.REACT_APP_API + '/groups/', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItems(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [auth, refreshKey])

    if (!isLoaded) {
        return <AxoneSpinner />;
    } else {
        return (
            <div>
                <h2><FontAwesomeIcon icon={faUsers}/> Groups</h2>
                {error.length > 0 && (
                    <FlashMessage duration="2000" persistOnHover={true}>
                        <div className="alert alert-danger my-2" role="alert">
                            <span>{error}</span>
                        </div>
                    </FlashMessage>
                )}
                {deleted && (
                    <FlashMessage duration="2000">
                        <div className="alert alert-success my-2" role="alert">Group successfully deleted</div>
                    </FlashMessage>
                )}
                <div className="table-responsive">
                    <table className="table table-striped table-hover table-sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th><Link to={"/groups/create"} title="Create new group">
                                    <button type="button" className="btn btn-primary btn-sm">
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </button>
                                </Link></th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map(item => (
                                <tr key={item.id}>
                                    <td>{item.id}</td>
                                    <td>{item.name}</td>
                                    <td>
                                        <Link to={"/groups/"+item.id} title={"Edit "+ item.name}>
                                            <button type="button" className="btn btn-primary btn-sm mr-2">
                                                <FontAwesomeIcon icon={faEdit}/>
                                            </button>
                                        </Link>
                                        <button type="button" className="btn btn-primary btn-sm mr-2" onClick={() => {deleteItem(item.id) } }>
                                            <FontAwesomeIcon icon={faTrash}/>
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
