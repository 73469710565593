const compareDate = (someDate, today) => {
    return someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
}
const getDay = (someDate) => {
    const today = new Date()
    if (compareDate(someDate, today)) {
        return 'today';
    }
    today.setDate(today.getDate() - 1);
    if (compareDate(someDate, today)) {
        return 'yesterday';
    }
    return someDate.toLocaleDateString('en-EN');
}
export function Time({ date, withTime }) {
    if (date === null || date === '') {
        return 'never';
    }
    let parsedDate = new Date(Date.parse(date));
    var result = getDay(parsedDate);
    if (withTime) {
        result += ' ' + parsedDate.getHours() + ':' + String(parsedDate.getMinutes()).padStart(2, "0");
    }
    return result;
}