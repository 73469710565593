import React, { useEffect, useState } from "react";
import {
    useParams
} from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from '../components/Auth';
import { AxoneSpinner } from '../components/AxoneSpinner';
import { Card, CardDeck, Button, Modal, Row, Col, Form } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal, faPlay, faStop, faSync, faInfo, faDice } from '@fortawesome/free-solid-svg-icons'
import { Time } from '../components/Time'

export function DeviceTypes() {
    return [
        'please choose',
        'MGW-Diamond'
    ]
}
export function EncoderChannel() {
    const { register, reset, setValue, getValues, watch } = useForm()

    const variants = ['primary', 'secondary'];
    const sdis = [1,2,3,4];

    const [title, setTitle] = useState('Channels')
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [item, setItem] = useState([])
    const [refreshKey, setRefreshKey] = useState(0)
    const [refreshState, setRefreshState] = useState(0)
    const [channels, setChannels] = useState([])
    const [inputs, setInputs] = useState([])
    const [channelstatistics, setChannelstatistics] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [modalData, setModalData] = useState({
        'srt': {},
        'name': '',
        'loading': true
    });
    const watchedForm = watch()

    let auth = useAuth();
    let { id } = useParams();
    document.title = title;

    const capitalize = (s) => {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }
    const handleClose = () => setShowModal(false);
    const handleShow = (channel_id, secondary) => {
        setModalData({
            'srt': '',
            'name': '',
            'loading': true
        })
        setShowModal(true);
        fetch(process.env.REACT_APP_API + '/encoders/channelstatistics/' + id, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    var statistics = JSON.parse(result)
                    var key = 'primary';
                    if (secondary) {
                        channel_id += 4;
                        key = 'secondary';
                    }
                    setModalData({
                        'srt': statistics[channel_id][key]['srt'][channel_id],
                        'name': statistics[channel_id][key]['zixi']['name'],
                        'loading': false
                    })
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log(error)
                }
            )
    }
    const generatePassword = (i, variant) => {
        let password = Array(10).fill("23456789ABCDEFGHKMNPQRSTUVWXYZabcdefghklmnpqrstuvwxyz").map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('');
        channels[i][variant]['aesPass'] = password;
        setValue("channelconfig[" + i + "]["+variant+"][aesPass]", password)
        setChannels(channels)
    }
    const handleBitrate = (d, channel_id, variant) => {
        if (typeof d.channelconfig === "undefined" || typeof d.channelconfig[channel_id][variant].bitrate === "undefined") {
            return ''
        }
        return d.channelconfig[channel_id][variant].bitrate
    }
    const triggerChannel = (channel_id, variant, primary_state, secondary_state) => {
        channelstatistics[channel_id].loading = true
        setChannelstatistics(channelstatistics)
        setRefreshState(oldKey => oldKey + 1)
        if (variant === "primary") {
            primary_state = !primary_state;
        } else {
            secondary_state = !secondary_state;
        }
        var source = getValues("channelconfig[" + channel_id + "][source]");
        if(source.length > 0){
            source = parseInt(source.slice(source.length - 1)) -1;
        } else {
            source = -1;
        }
        const config = {
            "twelveG": getValues("twelveG"),
            "testpattern": getValues("channelconfig[" + channel_id + "][" + variant +"][testpattern]"),
            "bitrate": getValues("channelconfig[" + channel_id + "][" + variant +"][bitrate]"),
            "source": source,
            "match": getValues("channelconfig[" + channel_id + "][" + variant +"][match]"),
            "resolution": getValues("channelconfig[" + channel_id + "][" + variant +"][resolution]"),
            "codec": getValues("channelconfig[" + channel_id + "][" + variant +"][codec]"),
            "encodeAudioCount": getValues("channelconfig[" + channel_id + "][" + variant +"][audio]"),
            "encryption": getValues("channelconfig[" + channel_id + "][" + variant +"][encryption]"),
            "aesMode": getValues("channelconfig[" + channel_id + "][" + variant +"][aesMode]"),
            "aesPass": getValues("channelconfig[" + channel_id + "][" + variant +"][aesPass]")
        }
        fetch(process.env.REACT_APP_API + '/encoders/control/' + id, {
            method: 'PUT',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify({
                'channel_id': channel_id,
                'config': config,
                'primary': primary_state,
                'secondary': secondary_state
            })
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response
        }).then(data => {
            channelstatistics[channel_id].loading = false
            setChannelstatistics(channelstatistics)
            setRefreshState(oldKey => oldKey + 1)
            setRefreshKey(oldKey => oldKey + 1)
        }).catch((error) => {
            channelstatistics[channel_id].loading = false
            setChannelstatistics(channelstatistics)
            setRefreshState(oldKey => oldKey + 1)
            console.log(error)
        });
    }
    useEffect(() => {
        fetch(process.env.REACT_APP_API + '/encoders/' + id, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItem(result);
                    setTitle("Channels for " + result.name)
                    try {
                        const newChans = JSON.parse(result.channels)
                        setChannels(newChans)
                    } catch (e) {

                    }
                    try {
                        const newIns = JSON.parse(result.inputs)
                        setInputs(newIns)
                    } catch (e) {

                    }
                    try {
                        var chans = JSON.parse(result.channelinfo)
                        chans.forEach(chan => {
                            chan.loading = false
                        });
                        setChannelstatistics(chans)
                    } catch (e) {
                    }
                    reset({
                        type: result.type
                    })
                    setTimeout(function () {
                        setValue('refresher', 1)
                    }, 500)
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [auth, id, reset, refreshKey, setTitle, setValue])
    if (error) {
        return (
            <div className="alert alert-danger" role="alert">
                <span>{error.message}</span>
            </div>
        );
    } else if (!isLoaded) {
        return <AxoneSpinner />;
    } else {
        let label = 'Last check: ' + Time({ date: item.last_status, withTime: true })
        if(item.statuscode !== 200){
            return (
                <div data-refreshs={refreshState}>
                    <h2>
                        {title}
                        <span className="float-right">
                            {item.statuscode === 200
                                ? <FontAwesomeIcon icon={faSignal} title={label} className="text-success" />
                                : <FontAwesomeIcon icon={faSignal} title={label} className="text-danger" />
                            }
                        </span>
                    </h2>
                    <div className="alert alert-danger" role="alert">
                        <span>No channels found; is the device online?</span>
                    </div>
                </div>
            )
        }
        return (
            <div data-refreshs={refreshState}>
                <h2>
                    {title}
                    <span className="float-right">
                        {item.statuscode === 200
                            ? <FontAwesomeIcon icon={faSignal} title={label} className="text-success" />
                            : <FontAwesomeIcon icon={faSignal} title={label} className="text-danger" />
                        }
                    </span>
                </h2>
                {variants.map((variant) => (
                    <div key={variant}>
                        {variant === "secondary" && (
                            <h3>{capitalize(variant)}</h3>
                        )}
                        <CardDeck className="my-1">
                            {channelstatistics.length === 0 && (
                                <Card>
                                    <Card.Header>No channels available</Card.Header>
                                    <Card.Body>
                                        Device is offline / no channels found
                                </Card.Body>
                                </Card>
                            )}
                            {channelstatistics.map((channel, i) => (
                                <Card key={'channel' + i} className={getValues('twelveG') && i > 0 && (
                                    "disabled"
                                )}>
                                    <Card.Header className="pb-0">
                                        <Button className="mr-2 float-left" disabled={item.statuscode !== 200} size="sm" variant={variant} onClick={() => handleShow(i, 0)}>
                                            <FontAwesomeIcon icon={faInfo} title="Statistics" />
                                        </Button>
                                        <small>Ch. {channel.name}</small>
                                        <Button className="float-right ml-3" disabled={channel.loading} size="sm" variant={channel[variant].status !== 'Error' ? variant : 'danger'} onClick={() => triggerChannel(i, variant, channel['primary'].status !== 'Stopped', channel['secondary'].status !== 'Stopped')}>
                                            {channel.loading
                                                ? <FontAwesomeIcon icon={faSync} title="Updating" />
                                                : channel[variant].status === 'Stopped'
                                                    ? <FontAwesomeIcon icon={faPlay} title="Start stream" />
                                                    : <FontAwesomeIcon icon={faStop} title="Stop stream" />
                                            }
                                        </Button>
                                        <small className={"float-right " + (channel[variant].status === "Playing" ? "text-success" : "text-danger")}>{channel[variant].status}</small>
                                    </Card.Header>
                                    <Card.Body className="pt-0">
                                        <Card.Text>
                                            {i === 0 && variant === 'primary' && (
                                                <span className="row mt-2">
                                                    <small className="col-5">
                                                        12G
                                                    </small>
                                                    <span className="col-7 text-right">
                                                        <label className="switch">
                                                            <input ref={register} type="checkbox" className="form-check-input" value="1" name="twelveG" defaultChecked={Array.isArray(inputs) && inputs.length > 0 && inputs[0]['mode'] === '12G-SDI'} />
                                                            <span></span>
                                                        </label>
                                                    </span>
                                                </span>
                                            )}
                                            {(i > 0 || variant === 'secondary') && (
                                                <span className="row mt-2">
                                                    <span className="spacer12g" />
                                                </span>
                                            )}
                                            <span className="row">
                                                <small className="col-8">
                                                    Total Bitrate (Mbps)
                                                </small>
                                                <small className="col-4">
                                                    {watchedForm ? handleBitrate(watchedForm, i, variant) : handleBitrate(channels[i][variant]['bitrate'], i, variant)}
                                                </small>
                                            </span>
                                            <Form.Control ref={register} defaultValue={channels[i][variant]['bitrate']} min="1" max="80" step="1" type="range" custom name={"channelconfig[" + i + "][" + variant + "][bitrate]"} />
                                            <span className="row mt-2">
                                                <small className="col-3">
                                                    Res. as Source
                                                </small>
                                                <span className="col-3 text-right">
                                                    <label className="switch">
                                                        <input ref={register} type="checkbox" className="form-check-input" value="1" name={"channelconfig[" + i + "][" + variant + "][match]"} defaultChecked={channels[i][variant]['match']} />
                                                        <span></span>
                                                    </label>
                                                </span>
                                                <small className="col-3">
                                                    Test-Pattern
                                                </small>
                                                <span className="col-3 text-right">
                                                    <label className="switch">
                                                        <input ref={register} type="checkbox" className="form-check-input" value="1" name={"channelconfig[" + i + "][" + variant + "][testpattern]"} defaultChecked={channels[i]['source']['forcePattern']} />
                                                        <span></span>
                                                    </label>
                                                </span>
                                            </span>
                                            {variant === "primary" && (
                                                <span className="row">
                                                    <small className="col-4">
                                                        Input
                                                    </small>
                                                    <select disabled={getValues("channelconfig[" + i + "][" + variant + "][match]")} className="form-control form-control-sm col-8" ref={register} defaultValue={channelstatistics[i]['source']} name={"channelconfig[" + i + "][source]"}>
                                                        {sdis.map((source) => (
                                                            <option key={"source"+source} value={"Input " +source}>SDI {source}</option>
                                                        ))}
                                                    </select>
                                                </span>
                                            )}
                                            <span className="row">
                                                <small className="col-4">
                                                    Video
                                                </small>
                                                <select disabled={getValues("channelconfig[" + i + "][" + variant + "][match]")} className="form-control form-control-sm col-8" ref={register} defaultValue={channels[i][variant]['width'] + 'x' + channels[i][variant]['height']} name={"channelconfig[" + i + "][" + variant + "][resolution]"}>
                                                    <option value="1280x720">1280x720p</option>
                                                    <option value="1920x1080">1920x1080p</option>
                                                    {i === 0 && getValues('twelveG') && (
                                                        <option value="2048x1080">2048x1080p</option>
                                                    )}
                                                    {i === 0 && getValues('twelveG') && (
                                                        <option value="3840x2160">3840x2160</option>
                                                    )}
                                                </select>
                                            </span>
                                            <span className="row">
                                                <small className="col-4">
                                                    Codec
                                                </small>
                                                <select className="form-control form-control-sm col-8" ref={register} defaultValue={channels[i][variant]['profile'] === 'HEVC 10-Bit 4:2:2' ? 8 : 6} name={"channelconfig[" + i + "][" + variant + "][codec]"}>
                                                    <option value="6">HEVC 8 Bit, 4:2:2</option>
                                                    <option value="8">HEVC 10 Bit, 4:2:2</option>
                                                </select>
                                            </span>
                                            <span className="row mt-2">
                                                <small className="col-5">
                                                    Encryption
                                                </small>
                                                <span className="col-7 text-right">
                                                    <label className="switch">
                                                        <input ref={register} type="checkbox" className="form-check-input" value="1" name={"channelconfig[" + i + "][" + variant + "][encryption]"} defaultChecked={channels[i][variant]['encryption']} />
                                                        <span></span>
                                                    </label>
                                                </span>
                                            </span>
                                            <span className="row">
                                                <select className="form-control form-control-sm col-4" ref={register} defaultValue={channels[i][variant]['aesMode'] === '256-bit (SRT)' ? 4 : 3} name={"channelconfig[" + i + "][" + variant + "][aesMode]"}>
                                                    <option value="2">128-bit</option>
                                                    <option value="3">256-bit</option>
                                                </select>
                                                <span className="col-6">
                                                    <input ref={register({ minLength: 10 })} defaultValue={channels[i][variant]['aesPass']} name={"channelconfig[" + i + "][" + variant + "][aesPass]"} type="text" className="form-control form-control-sm" placeholder="AES-Key" minLength="10" />
                                                </span>
                                                <span className="col-2 px-0 text-center">
                                                    <button onClick={() => generatePassword(i ,variant)} type="button" className="btn btn-primary btn-sm">
                                                        <FontAwesomeIcon icon={faDice} title="Generate Password" />
                                                    </button>
                                                </span>
                                            </span>
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            ))}
                        </CardDeck>
                    </div>
                ))}
                <Modal show={showModal} onHide={handleClose} animation={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalData.loading ? 'Loading' : 'Channel Statistics for ' + modalData.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {modalData.loading ? <AxoneSpinner /> : (
                            <span>
                                <Row>
                                    <Col sm="12">Listener targets: {modalData.srt.nListenerTargets}</Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <strong>Network</strong>
                                        <p>Total Bitrate (kbps): {modalData.srt.totalBitrate}<br />
                                    Round Time Trip(ms): {modalData.srt.roundTimeTrip}</p>
                                    </Col>
                                    <Col sm="6">
                                        <strong>Quality of Service</strong>
                                        <p>
                                            Total Packets: {modalData.srt.totalPackets}<br />
                                        Packet Loss (%): {modalData.srt.packetLoss}<br />
                                        Recovered Packets: {modalData.srt.recoveredPackets}<br />
                                        Non-Recovered Packets: {modalData.srt.nonRecoveredPackets}
                                        </p>
                                    </Col>
                                </Row>
                            </span>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
