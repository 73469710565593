import React, { useEffect, useState } from "react";
import {
    useParams
} from "react-router-dom";
import { useForm } from "react-hook-form";
import FlashMessage from '../components/FlashMessage';
import { useAuth } from '../components/Auth';
import { AxoneSpinner } from '../components/AxoneSpinner';
import { Spinner } from 'react-bootstrap'

export function User() {
    const duration = 10000;
    const { register, handleSubmit, getValues, setValue, errors, reset } = useForm();

    const [error, setError] = useState(null);
    const [saved, setSaved] = useState(null);
    const [setupComplete, setSetupComplete] = useState(false);
    const [submitting, setSubmitting] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState([]);
    const [streams, setStreams] = useState([]);
    const [userstreams, setUserstreams] = useState([]);
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [generatedPassword, setGeneratedPassword] = useState(false);
    let auth = useAuth();
    let { id } = useParams();
    const title = "Edit item #" + parseInt(id);

    const generatePassword = () => {
        let password = Array(8).fill("23456789ABCDEFGHKMNPQRSTUVWXYZabcdefghklmnpqrstuvwxyz").map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('');
        setValue('password', password, { shouldDirty: true });
        setValue('password2', password, { shouldDirty: true });
        setGeneratedPassword(true);
    }

    const isInGroup = (group_id) => {
        if (Array.isArray(item.groups)) {
            for (var i = 0; i < item.groups.length; i++) {
                if (item.groups[i].id === group_id) {
                    return true
                }
            }
        }
        return false
    }

    const onSubmit = data => {
        setSaved(false);
        setSubmitting(true);
        if (data.multiple_login === "0") {
            data.multiple_login = false
        }
        fetch(process.env.REACT_APP_API + '/users/' + id, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json()
        }).then(data => {
            setItem(data);
            setSaved(true);
            setSubmitting(false);
        }).catch((error) => {
            console.log(error);
            setSubmitting(false);
        });
    }
    const fetchUsers = function (auth) {
        fetch(process.env.REACT_APP_API + '/users/mandanten', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setUsers(result);
                    setIsLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (e) => {
                    setError(e);
                    setIsLoaded(true);
                }
            )
    }
    const fetchStreams = function (auth, id, reset, gcb, ucb) {
        fetch(process.env.REACT_APP_API + '/streams/?order_by=name&limit=1000', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setStreams(result);
                    gcb(auth, id, reset, ucb);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                }
            )
    }
    const fetchGroups = function (auth, id, reset, ucb) {
        fetch(process.env.REACT_APP_API + '/groups/', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setGroups(result);
                    ucb(auth, id, reset);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setError(error);
                }
            )
    }
    const fetchUser = function (auth, id, reset) {
        fetch(process.env.REACT_APP_API + '/users/' + id, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result.detail) {
                        setError(result.detail.msg)
                    } else {
                        if (!result.stream_id) {
                            result.stream_id = 0
                        }
                        var ustreams = []
                        result.streams.forEach((s) => {
                            ustreams.push(s.id)
                        })
                        setItem(result)
                        setUserstreams(ustreams)
                        setSetupComplete(true)
                    }
                    setIsLoaded(true)
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true)
                    setError(error)
                }
            )
    }
    const handleGroupButtonClick = (group_id, e) => {
        if (group_id === 2 && !auth.scopes.includes("admin")) {
            e.preventDefault()
        }
    }
    useEffect(() => {
        document.title = title
        if(auth.scopes.includes("admin")){
            fetchUsers(auth);
        }
        fetchStreams(auth, id, reset, fetchGroups, fetchUser, fetchUsers);
    }, [auth, id, reset, title])

    if (error) {
        return (
            <div className="alert alert-danger" role="alert">
                <span>{error.message}{error.detail}</span>
            </div>
        )
    } else if (!isLoaded || item.length === 0) {
        return <AxoneSpinner />
    } else {
        return (
            <div>
                <h2>{title}</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" ref={register} name="id" defaultValue={item.id} />
                    <input type="hidden" ref={register} name="deleted" defaultValue="0" />
                    {auth.scopes.includes("admin") && (
                        <div className="form-group">
                            <label htmlFor="user_id">Belongs to</label>
                            <select ref={register({ validate: value => parseInt(value) >= 0 })} defaultValue={item.owner_id} name="owner_id" className="form-control">
                                <option value="0">nobody</option>
                                {users.map((user) => (
                                    <option key={user.id} value={user.id}>{user.firstname.length > 0 || user.lastname.length > 0 ? user.firstname + ' ' + user.lastname : user.alias} {user.email.length > 0 ? '(' + user.email + ')' : ''}</option>
                                ))}
                            </select>
                        </div>
                    )}
                    <div className="form-group">
                        <label htmlFor="alias">Alias</label>
                        <input ref={register({ required: true })} required="required" defaultValue={item.alias} name="alias" type="text" className="form-control" placeholder="Alias" aria-describedby="aliasHelp" />
                        <small id="aliasHelp" className="form-text text-muted">Alternative name to login</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="name">First Name</label>
                        <input ref={register} defaultValue={item.firstname} name="firstname" type="text" className="form-control" placeholder="First Name" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="url">Last Name</label>
                        <input ref={register} defaultValue={item.lastname} name="lastname" type="text" className="form-control" placeholder="Last Name" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input ref={register} defaultValue={item.email} name="email" type="email" className="form-control" placeholder="Email" aria-describedby="emailHelp" />
                        <small id="emailHelp" className="form-text text-muted">Used as the "username" for the login as well</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="telephone">Phone</label>
                        <input ref={register} defaultValue={item.telephone} name="telephone" type="tel" className="form-control" placeholder="Phone" aria-describedby="telephoneHelp" />
                        <small id="telephoneHelp" className="form-text text-muted">Used in two factor auth. Format (eg. Germany): 004915112345678</small>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Streams</label>
                            </div>
                            <div className="form-group form-check">
                                {setupComplete ? (
                                    <select size="10" defaultValue={userstreams} ref={register} className="form-control" name="streams" multiple={true}>
                                        {streams.length > 0 && streams.map((stream, i) => {
                                            return (
                                                <option key={stream.id} value={stream.id}>{stream.name}</option>
                                            )
                                        })}
                                    </select>
                                ) : <Spinner animation="border" variant="light" size="sm"></Spinner> }
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label>Roles</label>
                            </div>
                            <div className="form-group form-check">
                                {groups.map((group, i) => {
                                    if (group.id !== 1 || auth.scopes.includes("admin")) {
                                        return (
                                            <span className="mr-5" key={group.id}>
                                                <input ref={register} type="checkbox" className="form-check-input" name={'groups[' + i + ']'} value={group.id} readOnly={!auth.scopes.includes('admin') && group.id === 2} onClick={(e) => handleGroupButtonClick(group.id, e)} defaultChecked={isInGroup(group.id)} />
                                                <label className="form-check-label">{group.name}</label>
                                            </span>
                                        )
                                    }
                                    return <input key={group.id} ref={register} type="hidden" name={'groups[' + i + ']'} value="0" />
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">New Password</label>
                        <input ref={register({ minLength: 8 })} name="password" defaultValue="" type={generatedPassword ? "text" : "password"} className="form-control" placeholder="New Password" aria-describedby="passwordHelp" minLength="8" />
                        <small id="passwordHelp" className="form-text text-muted">Fill in to set a new password.</small>
                        <button onClick={generatePassword} type="button" className="btn btn-sm btn-secondary btn-block mt-4">Generate Password</button>
                        {errors.password && <small className="form-text alert alert-danger">Please provide at least 8 characters</small>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password2">Retype Password</label>
                        <input ref={register({
                            validate: value => value === getValues("password") || 'Please check spelling'
                        })} defaultValue="" name="password2" type="password" className="form-control" placeholder="Retype Password" aria-describedby="password2Help" />
                        {errors.password2 && <small className="form-text alert alert-danger">{errors.password2.message}</small>}
                        <small id="password2Help" className="form-text text-muted">Retype the password to avoid spelling errors</small>
                    </div>
                    <div className="form-group">
                        <label>Two-Factor-Authentication</label>
                    </div>
                    <div className="form-group form-check form-check-inline">
                        <input ref={register} type="radio" className="form-check-input" name="f2a" value="0" defaultChecked={item.f2a === 0} />
                        <label className="form-check-label mr-3">Disabled</label>
                        <input ref={register} type="radio" className="form-check-input" name="f2a" value="1" defaultChecked={item.f2a === 1} />
                        <label className="form-check-label mr-3">Email (only, if email is set)</label>
                        <input ref={register} type="radio" className="form-check-input" name="f2a" value="2" defaultChecked={item.f2a === 2} />
                        <label className="form-check-label mr-3">SMS (if phone is missing falling back to email)</label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="login_failures">Failed login attempts since last login</label>
                        <input ref={register} defaultValue={item.login_failures} name="login_failures" type="number" className="form-control" placeholder="Failed login attempts" aria-describedby="lfHelp" />
                        <small id="lfHelp" className="form-text text-muted">more than 3 failures will suspend the account</small>
                    </div>
                    {auth.scopes.includes('admin') ? (
                        <div className="form-group form-check">
                            <input ref={register} type="checkbox" className="form-check-input" name="multiple_login" value="1" defaultChecked={item.multiple_login} />
                            <label className="form-check-label" htmlFor="disabled">Allow multiple logins</label>
                        </div>
                    ) : (
                        <input ref={register} type="hidden" name={'multiple_login'} value="0" />
                    )}
                    <div className="form-group form-check">
                        <input ref={register} type="checkbox" className="form-check-input" name="disabled" value="1" defaultChecked={item.disabled} />
                        <label className="form-check-label" htmlFor="disabled">Disable Account</label>
                    </div>
                    {error && (
                        <FlashMessage duration={duration} persistOnHover={true}>
                            <div className="alert alert-danger" role="alert"><span>{error}</span></div>
                        </FlashMessage>
                    )}
                    {saved && (
                        <FlashMessage duration={2000}>
                            <div className="alert alert-success my-2 text-center" role="alert">
                                <span>User successfully updated</span>
                            </div>
                        </FlashMessage>
                    )}
                    <button className="btn btn-lg btn-primary btn-block mt-4" type="submit">
                        {submitting ? (
                            <Spinner animation="border" variant="light" size="sm"></Spinner>
                        ) : "Save"}
                    </button>
                </form>
            </div>
        );
    }
}
