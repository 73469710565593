import React, { useContext, createContext, useState } from "react";
import packageJson from '../../package.json';

const authContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(authContext);
};
function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
};
function useProvideAuth() {
    const [token, setToken] = useState(
        localStorage.getItem('AuthToken') || ''
    );
    const [error, setError] = useState('');

    const errorDuration = 5000;
    var scopes = [];
    var username = '';

    const axoneAuth = {
        async signin(email, password, cb) {
            const payload = {
                username: email,
                password: password,
                grant_type: 'password'
            };
            const formData = Object.keys(payload).map((key) => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(payload[key]);
              }).join('&');
            await fetch(process.env.REACT_APP_API+'/token', {
                method: 'POST',
                cache: 'no-cache',
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Axone-Client": "cortex-"+packageJson.version
                },
                body: formData
            }).then(response => {
                if([500, 502].includes(response.status)){
                    throw new Error('Server error: '+response.status);
                }
                return response.json().then(data => ({ok: response.ok, status: response.status, body: data}))
            }).then(data => {
                if([401,403].includes(data.status)){
                    throw new Error('Access denied: '+data.body.detail);
                }
                if(!data.ok){
                    throw new Error('Network response was not ok');
                }
                cb(data.body,null);
            }).catch((error) => {
                cb(null,String(error));
            });
        },
        signout(cb) {
            axoneAuth.token = '';
            cb();
        },
        error(){
            return error;
        },
        setError(msg){
            setError(msg);
        }
    };
    const signin = (email, password, cb) => {
        return axoneAuth.signin(email, password, (data, error) => {
            if(data === null){
                if(error === null){
                    error = 'Empty server response';
                }
            } else {
                axoneAuth.token = data.access_token;
                localStorage.setItem('AuthToken', axoneAuth.token);
                setToken(data.access_token);
            }
            cb(data, error);
        });
    };
    const twoFactorAuth = (code, cb) => {
        fetch(process.env.REACT_APP_API+'/token/2fa', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Axone-Client": "cortex-"+packageJson.version,
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify({
                code: code
            })
        }).then(response => {
            if (!response.ok) {
                throw new Error('Could not validate two factor token');
            }
            return response.json()
        }).then(data => {
            localStorage.setItem('AuthToken', data.access_token);
            setToken(data.access_token);
            cb(data);
        }).catch((error) => {
            cb(null,String(error));
        });
    };
    const signout = cb => {
        return axoneAuth.signout(() => {
            localStorage.setItem('AuthToken', '');
            setToken(null);
            cb();
        });
    };
    if(typeof token === 'string' && token.length > 0){
        let decodedToken = parseJwt(token);
        username = decodedToken.sub;
        const expires = decodedToken.exp * 1000;
        if(Array.isArray(decodedToken.scopes)){
            scopes = decodedToken.scopes;
        }
        if(scopes.length > 0 && !scopes.includes('cortex')){
            setError('Cortex access is not permitted');
            setToken(null);
        } else if(Date.now() > expires){
            localStorage.setItem('AuthToken', '');
            setToken(null);
        } else {
            setTimeout(function(){
                localStorage.setItem('AuthToken', '');
                setToken(null);
            }, expires - Date.now());
        }
    }
    return {
        token,
        signin,
        signout,
        twoFactorAuth,
        setError,
        errorDuration,
        error,
        scopes,
        username
    };
}