import React, { useEffect, useState } from "react";
import {
    useParams
  } from "react-router-dom";
import { useForm } from "react-hook-form";
import FlashMessage from '../components/FlashMessage';
import { useAuth } from '../components/Auth';
import { AxoneSpinner } from '../components/AxoneSpinner';
import { Spinner } from 'react-bootstrap'

export function Group() {
    const duration = 10000;
    const { register, handleSubmit } = useForm();

    const [error, setError] = useState(null);
    const [saved, setSaved] = useState(null);
    const [submitting, setSubmitting] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState([]);
    let auth = useAuth();
    let { id } = useParams();
    const title = "Edit item #"+parseInt(id);

    const onSubmit = data => {
        setSaved(false);
        setSubmitting(true);
        fetch(process.env.REACT_APP_API+'/groups/'+id, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json()
        }).then(data => {
            setItem(data);
            setSaved(true);
            setSubmitting(false);
        }).catch((error) => {
            setError(error);
            setSubmitting(false);
        });
    }

    useEffect(() => {
        document.title = title;
        fetch(process.env.REACT_APP_API + '/groups/'+id, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true);
                    setItem(result);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }, [auth, id, title])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <AxoneSpinner />;
    } else {
        return (
            <div>
                <h2>{title}</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" ref={register} name="id" defaultValue={item.id}/>
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input ref={register({ required: true })} defaultValue={item.name} name="name" type="text" className="form-control" placeholder="Name" autoFocus />
                    </div>
                    {error && (
                        <FlashMessage duration={duration} persistOnHover={true}>
                            <div className="alert alert-danger" role="alert">{error}</div>
                        </FlashMessage>
                    )}
                    {saved && (
                        <FlashMessage duration="2000">
                            <div className="alert alert-success my-2 text-center" role="alert">
                                <span>Group successfully updated</span>
                            </div>
                        </FlashMessage>
                    )}
                    <button disabled={submitting} className="btn btn-lg btn-primary btn-block mt-4" type="submit">
                        {submitting ? (
                            <Spinner animation="border" variant="light" size="sm"></Spinner>
                        ) : "Save"}
                    </button>
                </form>
            </div>
        );
    }
}
