import packageJson from '../../package.json';
import { Link } from "react-router-dom";

export function Version() {
    return (
        <span className="copy">
            <Link to="/imprint">Imprint</Link> &copy; Ax-One '20-'{new Date().getFullYear()-2000} - V. {packageJson.version}
            {process.env.REACT_APP_API.indexOf('https://cortex-dev') === 0 && 
                <span className="ml-2 pulse_alert_danger">
                    <b>DEV</b>
                </span>
            }
        </span>
    );
}