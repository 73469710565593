import React from "react"
import { faChartBar } from '@fortawesome/free-solid-svg-icons'
import { ObjectListing } from '../components/ObjectListing'
import { Time } from '../components/Time'

export function Statistics() {
    const attributes = [
        {
            key: 'id',
            label: "#"
        },
        {
            key: 'created',
            label: "Date",
            format: (d) => {
                return Time({
                    date: d,
                    withTime: true
                })
            },
            order: true,
        },
        {
            key: 'duration',
            label: "Duration (s)",
            order: true,
        },
        {
            key: 'app_id',
            label: "Device",
            order: true,
        },
        {
            key: 'email',
            parent: "user",
            label: "User",
            order: true,
        },
        {
            key: "streaminfo",
            label: "Stream"
        }
    ];
    return <ObjectListing objectname="statistics" title="Statistics" icon={faChartBar} defaultSort="created" defaultAsc="false" attributes={attributes} add={false} edit={false} delete={false} search={true}/>
}