import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export function AxoneSpinner(props) {
    if(typeof props.table === "number" && props.table > 0){
        return (
            <tr className="table-spinner">
                <td colSpan={props.table} className="text-center"><FontAwesomeIcon icon={faSpinner} className="fa-pulse" alt="Loading" title="Loading"/></td>
            </tr>
        )
    }
    return (
        <div className="container text-center">
            <FontAwesomeIcon icon={faSpinner} className="fa-pulse" alt="Loading" title="Loading"/>
        </div>
    );
}