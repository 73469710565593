import React, { useEffect, useState } from "react";
import {
    useParams
} from "react-router-dom";
import { useForm } from "react-hook-form";
import FlashMessage from '../components/FlashMessage';
import { useAuth } from '../components/Auth';
import { AxoneSpinner } from '../components/AxoneSpinner';
import { Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignal } from '@fortawesome/free-solid-svg-icons'
import { Time } from '../components/Time'

export function DeviceTypes() {
    return [
        'please choose',
        'MGW-Diamond',
        'other'
    ]
}
export function Encoder() {
    const duration = 10000;
    const { register, handleSubmit, reset, setValue} = useForm()

    const [error, setError] = useState(null)
    const [saved, setSaved] = useState(null)
    const [deviceOfflineDuringSave, setDeviceOfflineDuringSave] = useState(null)
    const [submitting, setSubmitting] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [item, setItem] = useState([])

    let auth = useAuth()
    let { id } = useParams()
    const title = "Edit item #" + parseInt(id)

    const onSubmit = data => {
        setSaved(false)
        setSubmitting(true)
        fetch(process.env.REACT_APP_API + '/encoders/' + id, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (!response.ok) {
                return {'statuscode': response.status}
            }
            return response.json()
        }).then(data => {
            if(data.statuscode !== 200){
                setDeviceOfflineDuringSave(true)
            } else {
                setItem(data)
            }
            setSaved(true)
            setSubmitting(false)
            setTimeout(function(){
                setDeviceOfflineDuringSave(false)
            },5000)
        }).catch((error) => {
            setError(error);
            setSubmitting(false);
        });
    }
    const testChannels = function(){
        fetch(process.env.REACT_APP_API + '/encoders/debug/' + id, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {

                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }
    useEffect(() => {
        document.title = title;
        fetch(process.env.REACT_APP_API + '/encoders/' + id, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true)
                    setItem(result)

                    reset({
                        type: result.type
                    })
                    setTimeout(function(){
                        setValue('refresher',1)
                    },500)
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true)
                    setError(error)
                }
            )
    }, [auth, id, title, reset, setValue])

    if (error) {
        return (
            <div className="alert alert-danger" role="alert">
                <span>{error.message}</span>
            </div>
        );
    } else if (!isLoaded) {
        return <AxoneSpinner />;
    } else {
        const ls = new Date(item.last_status)
        const lastPing = Date.now() - ls.getTime()
        let label = 'Last check: ' + Time({ date: item.last_status, withTime: true })
        return (
            <div>
                <h2 onClick={testChannels}>
                    {title}
                    <span className="float-right">
                        {(item.statuscode === 200 && lastPing < 300000)
                            ? <FontAwesomeIcon icon={faSignal} title={label} className="text-success" />
                            : <FontAwesomeIcon icon={faSignal} title={label} className="text-danger" />
                        }
                    </span>
                </h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" ref={register} name="id" defaultValue={item.id} />
                    <input type="hidden" ref={register} name="refresher" defaultValue={0} />
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input ref={register({ required: true })} defaultValue={item.name} name="name" type="text" className="form-control" placeholder="Name" autoFocus />
                    </div>
                    <div className="form-group">
                        <label htmlFor="url">Host</label>
                        <input ref={register({ required: true })} defaultValue={item.url} name="url" type="text" className="form-control" placeholder="Host" aria-describedby="urlHelp" />
                        <small id="urlHelp" className="form-text text-muted">The URL:Port to the control interface accessible for cortex.ax-one.io</small>
                        {item.statuscode !== 200 &&
                            <small className="form-text text-danger">Error communicating with the device: {item.statuscode}: {item.statuscheck}</small>
                        }
                    </div>
                    <div className="form-group form-check">
                        <input ref={register} type="checkbox" className="form-check-input" name="tls" value="1" defaultChecked={item.tls} />
                        <label className="form-check-label" htmlFor="tls">Verify SSL-Certificate (needs to be setup in the device)</label>
                    </div>
                    <div className="form-group">
                        <label htmlFor="login">Login</label>
                        <input ref={register} defaultValue={item.login} name="login" type="text" className="form-control" placeholder="Login" aria-describedby="loginHelp" />
                        <small id="loginHelp" className="form-text text-muted">Username for API-Authentication</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input ref={register} defaultValue={item.password} name="password" type="text" className="form-control" placeholder="Password" aria-describedby="passwordHelp" />
                        <small id="passwordHelp" className="form-text text-muted">Password for API-Authentication</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Description</label>
                        <input ref={register} defaultValue={item.description} name="description" type="text" className="form-control" placeholder="Description" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="type">Device-Type</label>
                        <select ref={register({ validate: value => parseInt(value) > 0 })} defaultValue={item.type} name="type" className="form-control">
                            {DeviceTypes().map((label, i) => (
                                <option key={i} value={i}>{label}</option>
                            ))}
                        </select>
                    </div>
                    {error && (
                        <FlashMessage duration={duration} persistOnHover={true}>
                            <div className="alert alert-danger" role="alert">{error}</div>
                        </FlashMessage>
                    )}
                    {saved && (
                        <FlashMessage duration={2000}>
                            <div className="alert alert-success my-2 text-center" role="alert">
                                <span>Device successfully updated</span>
                            </div>
                        </FlashMessage>
                    )}
                    {deviceOfflineDuringSave && (
                        <FlashMessage duration={5000}>
                            <div className="alert alert-danger" role="alert">
                                <span>Device is currently offline, Stream could not be updated, please save again when the device is online!</span>
                            </div>
                        </FlashMessage>
                    )}
                    <button className="btn btn-lg btn-primary btn-block mt-4" type="submit">
                        {submitting ? (
                            <Spinner animation="border" variant="light" size="sm"></Spinner>
                        ) : "Save"}
                    </button>
                </form>
            </div>
        );
    }
}
