import { faTabletAlt } from '@fortawesome/free-solid-svg-icons'
import { ObjectListing } from '../components/ObjectListing'
import { useAuth } from '../components/Auth'

export function Receivers() {
    let auth = useAuth();
    const attributes = [
        {
            key: 'id',
            label: "#",
            order: true,
        },
        {
            key: 'name',
            label: "Name",
            order: true,
        },
        {
            key: 'type',
            label: "Typ",
            order: true,
        },
        {
            key: 'apple_id',
            label: "Apple ID",
            order: true,
        },
        {
            key: 'customer',
            label: "Customer",
            order: true,
        },
        {
            label: 'User',
            key: "user",
            format: (u) => {
                if(u == null) {
                    return 'nobody'
                }
                if (u.firstname.length > 0 || u.lastname.length > 0){
                    return u.firstname+' '+u.lastname
                }
                return u.alias
            }
        }
    ];
    if(auth.scopes.includes('admin')){
        attributes.push({
            label: 'Created by',
            key: "owner",
            format: (u) => {
                if (u.firstname.length > 0 || u.lastname.length > 0){
                    return u.firstname+' '+u.lastname
                }
                return u.alias
            }
        });
    }
    const actions = []
    return (
        <div>
            <ObjectListing objectname="receivers" title="Receiver" actions={actions} icon={faTabletAlt} attributes={attributes} defaultSort="name" add={true} edit={true} delete={true}/>
        </div>
    )
}