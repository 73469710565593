export function notify(msg) {
    const favicon = document.querySelector('[rel=icon]');
    favicon.href = "/favicon-glow.ico";
    if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
        return true;
    }
    else if (Notification.permission === "granted") {
        return goAhead(msg);
    }
}
export function unnotify(){
    const favicon = document.querySelector('[rel=icon]');
    favicon.href = "/favicon.ico";
}
function goAhead(msg) {
    if (msg.length > 0) {
        new Notification('Cortex!', {
            body: msg,
            tag: 'cortex-notification',
            icon: process.env.REACT_APP_DOMAIN + '/img/logo-glow512.png'
        });
    }
    return true;
}