import './scss/App.scss';

import { React } from "react";
import {
  BrowserRouter as Router,
  Link,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faUsers,
  faFilm,
  faVideo,
  faList,
  faServer,
  faChartBar,
  faPlay,
  faTabletAlt
} from '@fortawesome/free-solid-svg-icons'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { ProvideAuth, useAuth } from './components/Auth';
import { LoginForm } from './components/LoginForm';
import { Logout } from './components/Logout';
import { NotFound } from './components/NotFound';
import { Version } from './components/Version';
import { Websocket } from './components/Websocket';
import { Logs } from './pages/Logs';
import { TwoFactorAuth } from './components/TwoFactorAuth';
import { Dashboard } from './pages/Dashboard';
import { Decoders } from './pages/Decoders';
import { Encoders } from './pages/Encoders';
import { Encoder } from './pages/Encoder';
import { EncoderChannel } from './pages/EncoderChannel';
import { EncoderCreate } from './pages/EncoderCreate';
import { Group } from './pages/Group';
import { Groups } from './pages/Groups';
import { GroupCreate } from './pages/GroupCreate';
import { Imprint } from './pages/Imprint';
import { User } from './pages/User';
import { UserCreate } from './pages/UserCreate';
import { Users } from './pages/Users';
import { Receiver } from './pages/Receiver';
import { ReceiverCreate } from './pages/ReceiverCreate';
import { Receivers } from './pages/Receivers';
import { Statistics } from './pages/Statistics';
import { Streams } from './pages/Streams';
import { Stream } from './pages/Stream';
import { StreamCreate } from './pages/StreamCreate';
import { Showroom } from './pages/Showroom';

TimeAgo.addDefaultLocale(en)

export default function AxoneApp() {
  return (
    <ProvideAuth>
      <Router>
        <Navigation />
        <Routes>
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/users/2fa" element={<TwoFactorAuth />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/users/create" element={<PrivateRoute><UserCreate /></PrivateRoute>} />
          <Route path="/users/:id" element={<PrivateRoute><User /></PrivateRoute>} />
          <Route path="/users" element={<PrivateRoute><Users /></PrivateRoute>} />
          <Route path="/decoders" element={<PrivateRoute><Decoders /></PrivateRoute>} />
          <Route path="/encoders/create" element={<PrivateRoute><EncoderCreate /></PrivateRoute>} />
          <Route path="/encoders/channel/:id" element={<PrivateRoute><EncoderChannel /></PrivateRoute>} />
          <Route path="/encoders/:id" element={<PrivateRoute><Encoder /></PrivateRoute>} />
          <Route path="/encoders" element={<PrivateRoute><Encoders /></PrivateRoute>} />
          <Route path="/groups/create" element={<PrivateRoute><GroupCreate /></PrivateRoute>} />
          <Route path="/groups/:id" element={<PrivateRoute><Group /></PrivateRoute>} />
          <Route path="/groups" element={<PrivateRoute><Groups /></PrivateRoute>} />
          <Route path="/statistics" element={<PrivateRoute><Statistics /></PrivateRoute>} />
          <Route path="/receivers/create" element={<PrivateRoute><ReceiverCreate /></PrivateRoute>} />
          <Route path="/receivers/:id" element={<PrivateRoute><Receiver /></PrivateRoute>} />
          <Route path="/receivers" element={<PrivateRoute><Receivers /></PrivateRoute>} />
          <Route path="/streams/create" element={<PrivateRoute><StreamCreate /></PrivateRoute>} />
          <Route path="/streams/:id" element={<PrivateRoute><Stream /></PrivateRoute>} />
          <Route path="/streams" element={<PrivateRoute><Streams /></PrivateRoute>} />
          <Route path="/showroom" element={<PrivateRoute><Showroom /></PrivateRoute>} />
          <Route path="/logs" element={<PrivateRoute><Logs /></PrivateRoute>} />
          <Route path="/login" element={<LoginForm />} />
          <Route exact path="/" element={<LoginForm />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ProvideAuth>
  );
}

function Navigation() {
  let auth = useAuth();

  return auth.scopes.includes('2fa') ? (
    <nav className="navbar navbar-dark sticky-top flex-md-nowrap p-0">
      <Link className="col-sm-3 col-md-2 mr-0 p-2 pl-5" to="/dashboard">
        <img className="jello-horizontal" src={process.env.PUBLIC_URL + '/img/axone.svg'} alt="Ax-One" height="40" />
      </Link>
      <ul className="navbar-nav px-3">
        <li className="nav-item text-nowrap d-inline-flex">
          <Logout />
        </li>
      </ul>
    </nav>
  ) : null;
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  let location = useLocation();
  return auth.scopes.includes("2fa") ? (
    <div className="container-fluid px-4 pb-5">
      <div className="row">
        <nav className="col-md-2 d-none d-md-block sidebar">
          <div className="sidebar-sticky">
            <ul className="nav flex-column">
            <li className="nav-item">
                <Link className="nav-link" to="/showroom">
                  <FontAwesomeIcon icon={faVideo} /> Showroom
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/users">
                  <FontAwesomeIcon icon={faUser} /> Users
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/receivers">
                  <FontAwesomeIcon icon={faTabletAlt} /> Receivers
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/encoders">
                  <FontAwesomeIcon icon={faServer} /> Encoder
                </Link>
              </li>
              {false && (
                <li className="nav-item">
                  <Link className="nav-link" to="/decoders">
                    <FontAwesomeIcon icon={faPlay} /> Decoder
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link className="nav-link" to="/streams">
                  <FontAwesomeIcon icon={faFilm} /> Streams
                </Link>
              </li>
              {auth.scopes.includes('admin') && (
                <li className="nav-item">
                  <Link className="nav-link" to="/groups">
                    <FontAwesomeIcon icon={faUsers} /> Groups
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link className="nav-link" to="/logs">
                  <FontAwesomeIcon icon={faList} /> Logs
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/statistics">
                  <FontAwesomeIcon icon={faChartBar} /> Statistics
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        <main className="col-md-9 ml-sm-auto col-lg-10 pt-3 px-4" role="main">
          {children}
        </main>
        <footer className="px-2">
          <p className="text-muted text-right mb-1">
            <small>
              <Version />
            </small>
          </p>
          <Websocket />
        </footer>
      </div>
    </div>
  ) : (
    <Navigate
      to={{
        pathname: "/login",
        state: { from: location }
      }}
    />
  );
}