import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import FlashMessage from '../components/FlashMessage';
import { useAuth } from '../components/Auth';
import { useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap'
import { DeviceTypes } from "./Encoder";

export function EncoderCreate() {
    const duration = 10000;
    const { register, handleSubmit } = useForm();

    const [error, setError] = useState(null);
    const [saved, setSaved] = useState(null);
    const [submitting, setSubmitting] = useState(null);
    let auth = useAuth();
    const title = "Create new item";
    const navigate = useNavigate();

    const onSubmit = data => {
        setSaved(false);
        setSubmitting(true);
        fetch(process.env.REACT_APP_API + '/encoders/', {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json()
        }).then(data => {
            setSaved(true);
            setSubmitting(false);
            if(data.id > 0){
                setTimeout(function(){
                    navigate({pathname: "/encoders/"+data.id});
                },1000);
            }
        }).catch((e) => {
            setError(e.name + ': ' + e.message);
            setSubmitting(false);
        });
    }

    useEffect(() => {
        document.title = title;
    }, [title])

    return (
        <div>
            <h2>{title}</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" ref={register} name="id" value="0"/>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input ref={register({ required: true })} defaultValue="" name="name" type="text" className="form-control" placeholder="Name" autoFocus />
                </div>
                <div className="form-group">
                    <label htmlFor="url">Host</label>
                    <input ref={register({ required: true })} defaultValue="" name="url" type="text" className="form-control" placeholder="Host"  aria-describedby="urlHelp"/>
                    <small id="urlHelp" className="form-text text-muted">The URL:Port to the control interface accessible for cortex.ax-one.io</small>
                </div>
                <div className="form-group form-check">
                    <input ref={register} type="checkbox" className="form-check-input" name="tls" value="1" defaultChecked={false} />
                    <label className="form-check-label" htmlFor="tls">Verify SSL-Certificate (needs to be setup in the device)</label>
                </div>
                <div className="form-group">
                    <label htmlFor="login">Login</label>
                    <input ref={register} defaultValue="" name="login" type="text" className="form-control" placeholder="Login" aria-describedby="loginHelp"/>
                    <small id="loginHelp" className="form-text text-muted">Username for API-Authentication</small>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input ref={register} defaultValue="" name="password" type="text" className="form-control" placeholder="Password" aria-describedby="passwordHelp"/>
                    <small id="passwordHelp" className="form-text text-muted">Password for API-Authentication</small>
                </div>
                <div className="form-group">
                    <label htmlFor="description">Description</label>
                    <input ref={register} defaultValue="" name="description" type="text" className="form-control" placeholder="Description" />
                </div>
                <div className="form-group">
                    <label htmlFor="type">Device-Type</label>
                    <select ref={register({validate: value => parseInt(value) >0 })} defaultValue="0" name="type" className="form-control">
                        {DeviceTypes().map((label, i) => (
                            <option key={i} value={i}>{label}</option>
                        ))}
                    </select>
                </div>
                {error && (
                    <FlashMessage duration={duration} persistOnHover={true}>
                        <div className="alert alert-danger" role="alert">{error}</div>
                    </FlashMessage>
                )}
                {saved && (
                    <FlashMessage duration={2000}>
                        <div className="alert alert-success my-2 text-center" role="alert">
                            <span>Device successfully updated</span>
                        </div>
                    </FlashMessage>
                )}
                <button className="btn btn-lg btn-primary btn-block mt-4" type="submit">
                    {submitting ? (
                        <Spinner animation="border" variant="light" size="sm"></Spinner>
                    ) : "Save"}
                </button>
            </form>
        </div>
    );
}
