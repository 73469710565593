import React, { useState } from "react";
import { useAuth } from '../components/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faSignal, faHeartbeat, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import { ObjectListing } from '../components/ObjectListing'
import { Time } from '../components/Time'

export function Decoders() {
    let auth = useAuth();
    const [show, setShow] = useState(false);
    const [modalData, setModalData] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (device) => {
        setModalData(device)
        setShow(true);
    }

    const attributes = [
        {
            key: 'id',
            label: "#",
            order: true,
        },
        {
            key: 'name',
            label: "Name",
            order: true,
        },
        {
            key: 'description',
            label: "Beschreibung",
            order: true,
        },
        {
            key: "url",
            label: "Host",
            order: true,
        },
        {
            key: 'statuscode',
            label: 'Online',
            format: (statuscode, item) => {
                let label = 'Last check: '+Time({date: item.last_status, withTime:true})
                if(statuscode === 200){
                    return <FontAwesomeIcon icon={faSignal} title={label} className="text-success"/>;
                }
                return <FontAwesomeIcon icon={faSignal} title={label} className="text-danger"/>;
            }
        }
    ];
    const actions = [
        {
            icon: faHeartbeat,
            click: (id, device) => {
                if(device.statuscode === 200){
                    var cooling = JSON.parse(device.cooling)
                    var uptime = JSON.parse(device.uptime)
                    var data = {
                        "temp": cooling.chipTemperature,
                        "fan": cooling.fan,
                        "uptime": uptime.uptime,
                        "time": uptime.dateTime
                    }
                    handleShow(data);
                }
            }
        }
    ]
    if(auth.scopes.includes("admin")){
        attributes.push({
            label: 'Created by',
            key: "owner",
            format: (u) => {
                if (u.firstname.length > 0 || u.lastname.length > 0){
                    return u.firstname+' '+u.lastname
                }
                return u.alias
            }
        });
        actions.push(        {
            icon: faExternalLinkAlt,
            click: (id, device) => {
                window.open(device.url, "_blank")
            }
        });
    }
    return (
        <div>
            <ObjectListing objectname="decoders" title="Decoder" actions={actions} icon={faPlay} attributes={attributes} defaultSort="name" add={false} edit={true} delete={true}/>
            <Modal show={show} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Device Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm="6">
                            <p>Temp: {modalData.temp}<br/>
                            Fan: {modalData.fan}</p>
                        </Col>
                        <Col sm="6">
                            <p>Up Time: {modalData.uptime}<br/>
                            Date/Time: {modalData.time}</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}