import React, { useEffect, useState }  from "react"
import { useAuth } from '../components/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faUserCog, faCircle } from '@fortawesome/free-solid-svg-icons'
import { ObjectListing } from '../components/ObjectListing'
import { AxoneSpinner } from '../components/AxoneSpinner'
import { Time } from '../components/Time'

export function Users() {
    let auth = useAuth();
    var filters = [];
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [users, setUsers] = useState([]);

    const fetchUsers = function (auth) {
        fetch(process.env.REACT_APP_API + '/users/mandanten', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setUsers(result);
                    setIsLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (e) => {
                    setError(e);
                    setIsLoaded(true);
                }
            )
    }
    useEffect(() => {
        if(auth.scopes.includes("admin")){
            fetchUsers(auth);
        } else {
            setIsLoaded(true);
        }
    }, [auth])

    const rowClass = (i) => {
        if(i.last_websocket){
            const d = Date.parse(i.last_websocket);
            if(Date.now() - d < 70000) {
                return 'online';
            }
        }
        return '';
    }
    const attributes = [
        {
            key: 'last_websocket',
            label: "",
            order: false,
            format: (w) => {
                const d = new Date(w)
                if(Date.now() - d < 70000) {
                    return <FontAwesomeIcon icon={faCircle} title={"Last contact: "+d.toLocaleTimeString()}/>
                }
                return ''
            }
        },
        {
            key: 'alias',
            label: "Alias",
            order: true,
        },
        {
            key: 'firstname',
            label: "Firstname",
            order: true,
        },
        {
            key: "lastname",
            label: "Lastname",
            order: true,
        },
        {
            key: 'email',
            parent: "user",
            label: "Email",
            order: true,
        }
    ];
    if(auth.scopes.includes("admin")){
        attributes.push({
            label: 'Created by',
            key: "owner",
            format: (u) => {
                if(u == null) {
                    return 'nobody'
                }
                if (u.firstname.length > 0 || u.lastname.length > 0){
                    return u.firstname+' '+u.lastname
                }
                return u.alias
            }
        });
        attributes.push({
            key: 'last_login',
            label: 'Last login',
            order: true,
            format: (d) => {
                return Time({
                    date: d,
                    withTime: true
                })
            }
        });
        attributes.push({
            key: 'groups',
            label: 'Cortex-Access',
            format: (groups) => {
                if(Array.isArray(groups) && groups.length > 0){
                    for(var i=0;i<groups.length;i++){
                        if(groups[i].id === 1){
                            return <FontAwesomeIcon icon={faUserCog} title={groups[i].name} className="text-danger"/>
                        }
                        if(groups[i].id === 2){
                            return <FontAwesomeIcon icon={faUserCog} title={groups[i].name} className="text-grey"/>
                        }
                    }
                }
                return ''
            }
        });
        if(users.length > 0) {
            filters.push({
                label: 'Belongs to',
                requestAttribute: 'owner',
                toOption: (u) => {
                    if(u == null) {
                        return 'nobody'
                    }
                    if (u.firstname.length > 0 || u.lastname.length > 0){
                        return u.firstname+' '+u.lastname
                    }
                    return u.alias
                },
                options: users
            })
        }
    }
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <AxoneSpinner />;
    } else {
        return <ObjectListing objectname="users" title="Users" icon={faUser} attributes={attributes} rowClass={rowClass} defaultSort="email" defaultAsc="true" add={true} useuuid={true} edit={true} delete={true} filters={filters} search={true}/>
    }
}