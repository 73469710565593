import React, { useState } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { Alert, Spinner } from 'react-bootstrap'
import { useAuth } from './Auth'
import FlashMessage from './FlashMessage'
import { Version } from './Version';

export function LoginForm() {
    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();

    const { register, handleSubmit } = useForm();
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = data => {
        setSubmitting(true);
        let { from } = location.state || { from: { pathname: "/dashboard" } };

        auth.signin(data.axoneusername, data.password, (data, error) => {
            if (error || data === null) {
                setSubmitting(false);
                auth.setError(error);
                setTimeout(function () {
                    auth.setError(false);
                }, auth.errorDuration);
            } else {
                if ("Notification" in window) {
                    if (Notification.permission !== "denied" && Notification.permission !== "granted") {
                        Notification.requestPermission().then(function (permission) {
                            if (permission === "granted") {
                                return true;
                            }
                        });
                    }
                } else {
                    console.log("This browser does not support desktop notification");
                }
                setSubmitting(false);
                navigate(from);
            }
        });
    }
    if (auth.token) {
        if (!auth.scopes.includes("2fa")) {
            return <Navigate to={"/users/2fa"} />
        }
        return <Navigate to={"/dashboard"} />
    } else {
        return (
            <div className="d-flex justify-content-center align-items-center fullscreen">
                <form id="login" className="form-signin text-center" onSubmit={handleSubmit(onSubmit)}>
                    <img className="mb-4 jello-horizontal" src={process.env.PUBLIC_URL + '/img/axone.svg'} alt="Ax-One" width="196" />
                    <label htmlFor="inputEmail" className="sr-only">Your email/alias</label>
                    <input ref={register({ required: true })} name="axoneusername" type="text" id="inputEmail" className="form-control my-2 mt-4" placeholder="Email/Alias" autoFocus />
                    <label htmlFor="inputPassword" className="sr-only">Your password</label>
                    <input ref={register} name="password" type="password" id="inputPassword" className="form-control my-2" placeholder="Password" required />
                    {auth.error && (
                        <FlashMessage duration={auth.errorDuration} persistOnHover={true}>
                            <Alert variant="danger">
                                <span>
                                    {auth.error}
                                </span>
                            </Alert>
                        </FlashMessage>
                    )}
                    <button disabled={submitting} className="btn btn-lg btn-primary btn-block mt-4" type="submit" id="loginButton">
                        {submitting ? (
                            <Spinner animation="border" variant="light" size="sm"></Spinner>
                        ) : "Log in"}
                    </button>
                    <p className="mt-5 mb-3 text-muted">
                        <Version />
                    </p>
                </form>
            </div>
        )
    }
}