import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faSignal, faPlay, faStop, faSync, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { ObjectListing } from '../components/ObjectListing'
import { AxoneSpinner } from '../components/AxoneSpinner'
import { useAuth } from '../components/Auth'

export function Showroom() {
    let auth = useAuth()
    var filters = []
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [users, setUsers] = useState([])
    const [refreshState, setRefreshState] = useState(0)
    const [togglingChannel, setTogglingChannel] = useState(0)
    const [visibleUsers, setVisibleUsers] = useState(0)

    const triggerChannel = (device_id, channel_id, primary_state, secondary_state) => {
        setTogglingChannel(device_id)
        fetch(process.env.REACT_APP_API + '/encoders/toggle/' + device_id +'/'+channel_id+'/'+(primary_state ? 1: 0)+'/'+(secondary_state ? 1: 0), {
            method: 'PUT',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }
            return response
        }).then(data => {
            setRefreshState(oldKey => oldKey + 1)
            setTogglingChannel(0)
        }).catch((error) => {
            setRefreshState(oldKey => oldKey + 1)
            console.log(error)
            setTogglingChannel(0)
        });
    }

    const toggleStream = function(stream_id, user_id, active) {
        fetch(process.env.REACT_APP_API+'/streams/toggle/'+(active ? 0 : 1) +'/' + stream_id +'/'+user_id, {
            method: 'PUT',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }
            return response
        }).then(data => {
            setRefreshState(oldKey => oldKey + 1)
        }).catch((error) => {
            setRefreshState(oldKey => oldKey + 1)
            console.log(error)
        });
    }

    const isActiveUser = function(user_id, stream){
        var r = false
        stream.activeusers.forEach(u => {
            if(u.id === user_id){
                r = true
            }
        })
        return r
    }

    const fetchUsers = function (auth) {
        fetch(process.env.REACT_APP_API + '/users/mandanten', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setUsers(result);
                    setIsLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (e) => {
                    setError(e);
                    setIsLoaded(true);
                }
            )
    }
    useEffect(() => {
        if (auth.scopes.includes("admin")) {
            fetchUsers(auth)
        } else {
            setIsLoaded(true)
        }
    }, [auth, togglingChannel])

    const attributes = [
        {
            key: 'id',
            label: "#",
            order: true
        },
        {
            key: 'name',
            label: "Encoder",
            parent: 'device',
            order: true
        },
        {
            key: 'statuscode',
            parent: 'device',
            label: 'Online',
            format: (statuscode, item) => {
                if (statuscode === 200) {
                    return <FontAwesomeIcon icon={faSignal} className="text-success" />;
                }
                return <FontAwesomeIcon icon={faSignal} className="text-danger" />;
            }
        },
        {
            key: 'name',
            label: "Stream",
            order: true,
            click: (item) => {
                setVisibleUsers(item.id)
            }
        },
        {
            key: 'users',
            label: "Participants",
            order: false,
            click: (item) => {
                setVisibleUsers(item.id)
            },
            format: (users) => {
                var online = 0;
                users.forEach(u => {
                    const d = Date.parse(u.last_websocket);
                    if(Date.now() - d < 70000) {
                        online++
                    }
                });
                return <div>
                    <span className={online > 0 ? 'online' : ''}>{online}</span>/{users.length}
                </div>
            }
        }
    ]

    const subrows = [
        {
            model: 'users',
            key: 'id',
            label: "#",
            class: 'text-right',
            hidden: (id) => {
                return id !== visibleUsers
            },
            rowClass: (i) => {
                if(i.last_websocket){
                    const d = Date.parse(i.last_websocket);
                    if(Date.now() - d < 70000) {
                        return 'online';
                    }
                }
                return '';
            }
        },
        {
            model: 'users',
            key: 'alias',
            label: 'Alias',
            colspan: 4
        },
        {
            model: 'users',
            key: 'alias',
            label: 'Play',
            format: (name, item, parent) => {
                return <button type="button" className="btn btn-primary btn-sm mr-2" onClick={() => { toggleStream(parent.id, item.id, isActiveUser(item.id,parent)) }}>
                        {isActiveUser(item.id,parent) ? <FontAwesomeIcon title="Disable user" icon={faEye} /> : <FontAwesomeIcon title="Activate user" icon={faEyeSlash} />}
                    </button>
            }
        }
    ]
    const isPlaying = (item) => {
        if(item.device?.statuscode === 200){
            var channel_id = item.channel_id-1
            if(channel_id<0){
                channel_id=0
            }
            if(item.device.channels.length > 0){
                try {
                    var channels = JSON.parse(item.device.channels)
                    var statusCode = 'secondaryStatus'
                    if(item.primary_channel){
                        statusCode = 'primaryStatus'
                    }
                    if(channels[channel_id][statusCode] === 'Stopped'){
                        return true
                    }
                } catch (e){
                    console.log('device '+item.device.id+' contains faulty channels')
                }
            }
        }
        return false
    }
    const actions = [
        {
            icon: faEye,
            title: 'Allow all users',
            condition: () => {
                return true
            },
            click: (id, item) => {
                toggleStream(item.id,0,false)
            }
        },
        {
            icon: faEyeSlash,
            title: 'Disallow all users',
            condition: () => {
                return true
            },
            click: (id, item) => {
                toggleStream(item.id,0,true)
            }
        },
        {
            icon: faSync,
            title: 'Toggling channel',
            condition: (item) => {
                return togglingChannel === item.device?.id
            },
            click: (id, item) => {
                return false
            }
        },
        {
            icon: faPlay,
            title: 'Play',
            condition: (item) => {
                return togglingChannel === 0 &&isPlaying(item)
            },
            click: (id, item) => {
                if(item.device?.statuscode === 200){
                    var channel_id = item.channel_id-1
                    if(channel_id<0){
                        channel_id=0
                    }
                    if(item.device.channels.length > 0){
                        try {
                            var channels = JSON.parse(item.device.channels)
                            var statusCode = 'secondaryStatus'
                            if(item.primary_channel){
                                statusCode = 'primaryStatus'
                            }
                            channels[channel_id][statusCode] = 'Playing'
                            triggerChannel(item.device_id, channel_id, channels[channel_id]['primaryStatus'] === 'Playing', channels[channel_id]['secondaryStatus'] === 'Playing')
                        } catch(e){
                            
                        }
                    }
                }
            }
        },
        {
            icon: faStop,
            title: 'Stop',
            condition: (item) => {
                if(togglingChannel > 0) {
                    return false
                }
                if(item.device?.statuscode === 200){
                    var channel_id = item.channel_id-1
                    if(channel_id<0){
                        channel_id=0
                    }
                    if(item.device.channels.length > 0){
                        try {
                            var channels = JSON.parse(item.device.channels)
                            var statusCode = 'secondatyStatus'
                            if(item.primary_channel){
                                statusCode = 'primaryStatus'
                            }
                            if(channels[channel_id][statusCode] === 'Playing'){
                                return true;
                            }
                        } catch(e){

                        }
                    }                  
                }
                return false;
            },
            click: (id, item) => {
                if(item.device?.statuscode === 200){
                    var channel_id = item.channel_id-1
                    if(channel_id<0){
                        channel_id=0
                    }
                    if(item.device.channels.length > 0){
                        try {
                            var channels = JSON.parse(item.device.channels)
                            var statusCode = 'secondaryStatus'
                            if(item.primary_channel){
                                statusCode = 'primaryStatus'
                            }
                            channels[channel_id][statusCode] = 'Stopped'
                            triggerChannel(item.device_id, channel_id, channels[channel_id]['primaryStatus'] === 'Playing', channels[channel_id]['secondaryStatus'] === 'Playing')
                        } catch (e){

                        }
                    }
                }
            }
        }
    ]
    if (auth.scopes.includes('admin')) {
        if (users.length > 0) {
            filters.push({
                label: 'Belongs to',
                requestAttribute: 'owner',
                toOption: (u) => {
                    if (u == null) {
                        return 'nobody'
                    }
                    if (u.firstname.length > 0 || u.lastname.length > 0) {
                        return u.firstname + ' ' + u.lastname
                    }
                    return u.alias
                },
                options: users
            })
        }
    }
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <AxoneSpinner />;
    } else {
        return <ObjectListing refresh={refreshState} objectname="streams" action="showroom" title="Showroom" icon={faVideo} attributes={attributes} defaultSort="name" add={false} edit={false} delete={false} filters={filters} search={true} subrows={subrows} actions={actions} />
    }
}