import React, { useEffect, useState } from "react";
import {
    useParams
} from "react-router-dom";
import { useForm } from "react-hook-form";
import FlashMessage from '../components/FlashMessage';
import { useAuth } from '../components/Auth';
import { AxoneSpinner } from '../components/AxoneSpinner';
import { Spinner } from 'react-bootstrap'

export function Stream() {
    const duration = 10000;
    const { register, handleSubmit, reset } = useForm();

    const [error, setError] = useState(null);
    const [saved, setSaved] = useState(null);
    const [submitting, setSubmitting] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState({});
    const [devices, setDevices] = useState([]);
    let auth = useAuth();
    let { id } = useParams();
    const title = "Edit item #" + parseInt(id);

    const onSubmit = data => {
        setSaved(false);
        setSubmitting(true);
        fetch(process.env.REACT_APP_API + '/streams/' + id, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
            body: JSON.stringify(data)
        }).then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json()
        }).then(data => {
            setItem(data);
            setSaved(true);
            setSubmitting(false);
        }).catch((error) => {
            setError(error);
            setSubmitting(false);
        });
    }
    const fetchStream = (auth, id, reset) => {
        fetch(process.env.REACT_APP_API + '/streams/' + id, {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setIsLoaded(true)
                    setItem(result)
                    reset({
                        device_id: result.device_id > 0 ? result.device_id : 0,
                        channel_id: result.channel_id,
                        primary_channel: result.primary_channel ? 1: 0
                    })
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }
    const fetchDevices = (auth, id, reset, scb) => {
        fetch(process.env.REACT_APP_API + '/encoders/', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setDevices(result);
                    scb(auth, id, reset);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )
    }
    useEffect(() => {
        document.title = title;
        fetchDevices(auth, id, reset, fetchStream);
    }, [auth, id, reset, title])

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <AxoneSpinner />;
    } else {
        return (
            <div>
                <h2>{title}</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" ref={register} name="id" defaultValue={item.id} />
                    <input type="hidden" ref={register} name="streamid" defaultValue={item.id} />
                    <input type="hidden" ref={register} name="deleted" defaultValue="0" />
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input ref={register({ required: true })} defaultValue={item.name} name="name" type="text" className="form-control" placeholder="Name" autoFocus />
                    </div>
                    <div className="form-group">
                        <label htmlFor="device_id">Device</label>
                        <select ref={register} defaultValue={item.device_id > 0 ? item.device_id : 0} name="device_id" className="form-control">
                            <option value="0">other / unconfigured</option>
                            {devices.map((device) => (
                                <option key={device.id} value={device.id}>{device.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="device_id">Channel</label>
                        <select ref={register({ validate: value => parseInt(value) > 0 })} defaultValue={item.channel_id} name="channel_id" className="form-control">
                            <option value="0">please choose</option>
                            {[1, 2, 3, 4].map((channel) => (
                                <option key={"channel" + channel}>{channel}</option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="subchannel">Subchannel</label>
                        <select ref={register({ validate: value => parseInt(value) !== -1 })} defaultValue={item.primary_channel ? '1': '0'} name="primary_channel" className="form-control">
                            <option value="-1">please choose</option>
                            <option value="1">Primary</option>
                            <option value="0">Secondary</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="url">URI</label>
                        <input ref={register({ required: true })} defaultValue={item.url} name="url" type="text" className="form-control" placeholder="URI" aria-describedby="urlHelp" />
                        <small id="urlHelp" className="form-text text-muted">Will be overwritten on device updates; The complete URI including Protocol and Port such as: srt://my.domain.tld:1234</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="buffering">Buffering (sec.)</label>
                        <input ref={register({ required: true })} defaultValue={item.buffering} name="buffering" min=".5" max="3" step=".5" type="number" className="form-control" placeholder="Buffering (sec)" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="offset">Offset (sec.)</label>
                        <input ref={register({ required: true })} defaultValue={item.offset} min="0" max="10" step="1" name="offset" type="number" className="form-control" placeholder="Offset (sec)" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="mode">Mode</label>
                        <select ref={register} defaultValue={item.mode} name="mode" className="form-control">
                            <option value="0">Auto-detect</option>
                            <option value="1">Video only</option>
                            <option value="2">Audio only</option>
                        </select>
                    </div>
                    <input ref={register({ required: true })} defaultValue={item.bitrate} name="bitrate" type="hidden" className="form-control" placeholder="Bitrate (kbps)" />
                    <div className="form-group">
                        <label htmlFor="passphrase">Password</label>
                        <input ref={register} defaultValue={item.passphrase} name="passphrase" type="text" className="form-control" placeholder="Passphrase" aria-describedby="passphraseHelp"/>
                        <small id="passphraseHelp" className="form-text text-muted">Will be overwritten on device updates</small>
                    </div>
                    <input ref={register} type="hidden" defaultValue={item.pbkeylen} name="pbkeylen" className="form-control"/>
                    <div className="form-group">
                        <label htmlFor="latency">Latency (msec.)</label>
                        <input ref={register({ required: true })} defaultValue={item.latency} required="required" min="0" name="latency" type="number" className="form-control" placeholder="Latency (msec.)" />
                    </div>
                    <input ref={register({ required: true })} required="required" defaultValue={item.maxbw} name="maxbw" type="hidden" className="form-control" placeholder="Max. bandwidth (bytes/sec.)" />
                    <input ref={register} type="hidden" defaultValue={item.http_type} name="http_type" className="form-control"/>
                    <input ref={register} type="hidden" className="form-check-input" name="steady_enabled" defaultValue={item.steady_enabled} />
                    <input ref={register} type="hidden" className="form-check-input" name="steady_supported" defaultValue={item.steady_supported} />
                    {error && (
                        <FlashMessage duration={duration} persistOnHover={true}>
                            <div className="alert alert-danger" role="alert">{error}</div>
                        </FlashMessage>
                    )}
                    {saved && (
                        <FlashMessage duration={2000}>
                            <div className="alert alert-success my-2 text-center" role="alert">
                                <span>Stream successfully updated</span>
                            </div>
                        </FlashMessage>
                    )}
                    <button className="btn btn-lg btn-primary btn-block mt-4" type="submit">
                        {submitting ? (
                            <Spinner animation="border" variant="light" size="sm"></Spinner>
                        ) : "Save"}
                    </button>
                </form>
            </div>
        );
    }
}
