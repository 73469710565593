import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from './Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

export function Logout() {
    const [navigate, setNavigate] = useState(false);
    let auth = useAuth();

    const logout = (e) =>{
        e.preventDefault();
        auth.signout(function(){
            setNavigate(true)
        })
    }
    if(navigate){
        return <Navigate to={ "/" } />
    }
    return (
        <div className="d-inline-flex">
            <span title="Hi :)">{auth.username}</span> - <a title="Sign out" className="nav-link py-0 px-2 text-danger" href="/logout" onClick={logout}><FontAwesomeIcon icon={faSignOutAlt}/></a>
        </div>
    )
}