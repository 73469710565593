import { Link } from "react-router-dom"
export function Imprint() {

    return (
        <section id="imprint" className="d-flex flex-column justify-content-center align-items-center fullscreen">
            <img className="mb-4 jello-horizontal" src={process.env.PUBLIC_URL + '/img/axone.svg'} alt="Ax-One" width="196" />
            <h2>Ax-One.io</h2>
            <p>
                Berkan Ustalar<br/>
                Ohmstraße 9, 10179 Berlin
            </p>
            <p>
                <a href="mailto:info@ax-one.io">info@ax-one.io</a><br/>
                +49 173 4896489
            </p>
            <p>
                <Link className="nav-link" to="/">
                    Return to start
                </Link>
            </p>
        </section>
    )
}