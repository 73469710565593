import React, { useState }  from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import { useAuth } from './Auth';

export function TwoFactorAuth() {
    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();

    const { register, handleSubmit } = useForm();
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = data => {
        setSubmitting(true);
        let { from } = location.state || { from: { pathname: "/dashboard" } };
        auth.twoFactorAuth(data.axone2fa, (data, error) => {
            if (error || data === null) {
                setSubmitting(false);
                auth.setError(error);
                setTimeout(function(){
                    auth.setError(false);
                }, auth.errorDuration);
                auth.signout(function(){
                    navigate({pathname: "/"});
                });
            } else {
                setSubmitting(false);
                navigate(from);
            }
        });
    }
    const onCancel = () => {
        auth.signout(function(){
            navigate({pathname: "/"});
        });
    }
    const onKeyUp = e =>{
        e.target.value = e.target.value.toUpperCase()
        if(e.target.value.length === 6){
            handleSubmit(onSubmit)()
        }
    }
    if(auth.scopes.includes('2fa')){
        return <Navigate to={ "/dashboard" } />
    } else {
        return (
            <div className="d-flex justify-content-center align-items-center fullscreen">
                <form id="login" className="form-signin text-center" onSubmit={handleSubmit(onSubmit)}>
                    <img className="mb-4 jello-horizontal" src={process.env.PUBLIC_URL + '/img/axone.svg'} alt="Ax-One" width="196" />
                    <label htmlFor="axone2fa" className="sr-only">Security Code</label>
                    <input ref={register({ required: true })} name="axone2fa" type="text" id="inputCode" onKeyUp={onKeyUp} minLength="6" maxLength="6" className="form-control my-2 mt-4" placeholder="Security Code" autoFocus />
                    <button disabled={submitting} className="btn btn-lg btn-primary btn-block mt-4" type="submit" id="loginButton">
                        {submitting ? (
                            <Spinner animation="border" variant="light" size="sm"></Spinner>
                        ) : "Confirm"}
                    </button>
                    <button className="btn btn-lg btn-primary btn-block mt-4" type="button" onClick={onCancel}>Cancel</button>
                    <p className="mt-5 mb-3 text-muted">&copy; Ax-One 2020 - {new Date().getFullYear()}</p>
                </form>
            </div>
        )
    }
}