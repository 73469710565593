import React, { useEffect, useState } from "react"
import { faFilm} from '@fortawesome/free-solid-svg-icons'
import { ObjectListing } from '../components/ObjectListing'
import { AxoneSpinner } from '../components/AxoneSpinner'
import { useAuth } from '../components/Auth'

export function Streams() {
    let auth = useAuth();
    var filters = [];
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [users, setUsers] = useState([]);

    const fetchUsers = function (auth) {
        fetch(process.env.REACT_APP_API + '/users/mandanten', {
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + auth.token
            },
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setUsers(result);
                    setIsLoaded(true);
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (e) => {
                    setError(e);
                    setIsLoaded(true);
                }
            )
    }
    useEffect(() => {
        if(auth.scopes.includes("admin")){
            fetchUsers(auth);
        } else {
            setIsLoaded(true);
        }
    }, [auth])

    const attributes = [
        {
            key: 'id',
            label: "#",
            order: true
        },
        {
            key: 'name',
            label: "Name",
            order: true
        }
    ];
    if(auth.scopes.includes('admin')){
        attributes.push({
            label: 'Created by',
            key: "owner",
            format: (u) => {
                if (u.firstname.length > 0 || u.lastname.length > 0){
                    return u.firstname+' '+u.lastname
                }
                return u.alias
            }
        });
        if(users.length > 0) {
            filters.push({
                label: 'Belongs to',
                requestAttribute: 'owner',
                toOption: (u) => {
                    if(u == null) {
                        return 'nobody'
                    }
                    if (u.firstname.length > 0 || u.lastname.length > 0){
                        return u.firstname+' '+u.lastname
                    }
                    return u.alias
                },
                options: users
            })
        }
    }
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <AxoneSpinner />;
    } else {
        return <ObjectListing objectname="streams" title="Streams" icon={faFilm} attributes={attributes} defaultSort="name" add={true} edit={true} delete={true} filters={filters} search={true}/>
    }
}