import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFrownOpen } from '@fortawesome/free-regular-svg-icons'
import { Version } from './Version';

export function NotFound() {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center fullscreen">
            <img className="mb-4 jello-horizontal" src={process.env.PUBLIC_URL + '/img/axone.svg'} alt="Ax-One" width="196" />
            <h2>404 <FontAwesomeIcon icon={faFrownOpen}/></h2>
            <p>Sorry, we could not find the requested page</p>
            <p>
                <Link className="nav-link" to="/">
                    Return to start
                </Link>
            </p>
            <p className="mt-5 mb-3 text-muted">
                <Version />
            </p>
        </div>
    );
}