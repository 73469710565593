import { useEffect, useState } from "react"
import { w3cwebsocket as W3CWebSocket } from "websocket"
import { Toast } from 'react-bootstrap'
import React from 'react'
import ReactTimeAgo from 'react-time-ago'
import { useAuth } from '../components/Auth'
import { notify, unnotify } from '../components/DesktopNotification'

export function Websocket() {
  let auth = useAuth();
  const [connected, setConnected] = useState(false);
  const [messages, setMessages] = useState([]);
  const [renderTime, setRenderTime] = useState(0);
  const messageDuration = 6000;
  const retryDelay = 10000;
  const inactiveDelay = 60000;
  var hidden, visibilityChange;
  if (typeof document.hidden !== "undefined") {
    hidden = "hidden";
    visibilityChange = "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
  }
  const handleVisibilityChange = () => {
    if (!document[hidden]) {
      unnotify();
    }
  }
  if (typeof document.addEventListener !== "undefined" && hidden !== undefined) {
    document.addEventListener(visibilityChange, handleVisibilityChange, false);
  }
  const closeToast = (id) => {
    const updatedMessages = messages.map(message => {
      if (message.id === id) {
        message.discarded = true;
      }
      return message;
    })
    setMessages(updatedMessages);
  }
  useEffect(() => {
    var wsWatcher = null
    var wsOnlinePing = null
    if (connected) {
      return
    }
    console.log('ws: connecting to ' + process.env.REACT_APP_WEBSOCKET)
    const client = new W3CWebSocket(process.env.REACT_APP_WEBSOCKET);
    client.onopen = () => {
      wsWatcher = setInterval(() => {
        if (localStorage.getItem('AuthToken').length === 0) {
          client.close();
        }
      }, 1000);
      wsOnlinePing = setInterval(() => {
        client.send(JSON.stringify({ alive: 1 }));
      }, 60666);
      const payload = {
        token: auth.token
      };
      client.send(JSON.stringify(payload));
      setConnected(true);
      console.log('ws: connected');
      if (typeof hidden === undefined || document[hidden]) {
        setTimeout(() => {
          client.close();
        }, inactiveDelay)
      }
    };
    client.onclose = () => {
      clearInterval(wsWatcher)
      clearInterval(wsOnlinePing)
      setTimeout(() => {
        setConnected(false)
      }, retryDelay);
      console.log('ws: closed')
    }
    client.onmessage = (message) => {
      if (message.data.length > 0) {
        try {
          let data = JSON.parse(message.data);
          if (data.status !== 200) {
            console.log('ws: error #' + data.status);
            return false;
          }
          if (typeof data.created === "undefined") {
            data.created = new Date();
          } else {
            data.created = Date.parse(data.created);
          }
          data.discarded = false;
          if (data.title === "New Login") {
            return true;
          }
          if (data.notification && !data.silent) {
            if (typeof hidden === undefined || document[hidden]) {
              notify(data.msg);
              data.discarded = true;
            }
          }
          messages.push(data);
          data.id = messages.length;
          if (!document[hidden]) {
            setTimeout(() => {
              const updatedMessages = messages.map(message => {
                if (message.id === data.id) {
                  message.discarded = true;
                }
                return message;
              })
              setMessages(updatedMessages);
            }, messageDuration);
          }
          setMessages(messages);
          setRenderTime(Date.now());
        } catch (e) {
          console.log('ws: failed parsing message');
        }
      } else {
        console.log('ws: received empty message');
      }
    };
    return () => {
      clearInterval(wsWatcher)
      clearInterval(wsOnlinePing)
    }
  }, [auth.token, connected, messages, hidden, inactiveDelay, messageDuration, renderTime])
  return (
    <section id="notifications">
      {messages.filter((message) => !message.discarded).map((message) => {
        return (
          <Toast transition={false} key={message.id} show={true} onClose={() => closeToast(message.id)}>
            <Toast.Header>
              <img
                src={process.env.PUBLIC_URL + '/img/axone.svg'}
                width={20}
                className="rounded mr-2"
                alt={message.title}
              />
              <strong className="mr-auto">{message.title}</strong>
              <small><ReactTimeAgo date={message.created} locale="en-US" /></small>
            </Toast.Header>
            <Toast.Body>{message.msg}</Toast.Body>
          </Toast>
        )
      })}
    </section>
  )
}